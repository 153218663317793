import { Alert, Col, Divider, Layout, Row } from "antd";
import DocumentTable from "../../components/Document/DocumentTable/DocumentTable.component";
import OperatorDocumentFilter from "../../components/OperatorDocument/OperatorDocumentFilter/OperatorDocumentFilter.component";
import OperatorDocumentContext from "../../contexts/OperatorDocument.context";
import OperatorDocumentProvider from "../../providers/OperatorDocument.provider";
import { IDocument } from "../../types/IDocument.interface";
import { Content } from 'antd/lib/layout/layout';

export default () => {

    const upload = (document: IDocument) => {
        console.log(':: 🚀 upload', document.TypeName);
    }

    const download = (document: IDocument) => {
        console.log(':: 🚀 download', document.TypeName);
    }

    const activity = (document: IDocument) => {
        console.log(':: 🚀 activity', document.TypeName);
    }

    return <OperatorDocumentProvider>
        <OperatorDocumentContext.Consumer>
            {
                ({ searching, documents, refresh }) => {
                    return <Content>
                        <OperatorDocumentFilter searching={searching} refresh={refresh} />
                        <Divider />
                        <DocumentTable searching={searching} documents={documents} refresh={refresh} />
                    </Content>
                }
            }
        </OperatorDocumentContext.Consumer>
    </OperatorDocumentProvider >
}