import { withAuthenticationRequired, WithAuthenticationRequiredOptions } from '@auth0/auth0-react';

export default ({ element, permission, ...propsForComponent }: any) => {
  
  const options: WithAuthenticationRequiredOptions = {
    returnTo: '/unauthorized',

    claimCheck: (claims: any) => {

      if (!permission)
        return true;

      else if (!claims)
        return false;

      const userPermissions = claims['https://operator.yourworkforce.com.au/user/permissions']

      if (!Array.isArray(userPermissions))
        return false;

      return userPermissions.includes(permission);
    }
  }

  const Cp = withAuthenticationRequired(element, options);
  return <Cp {...propsForComponent} />
}

