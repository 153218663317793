import { useEffect, useState } from "react";
import { RetrieveSubcontractorRegions, SaveSubcontractorRegions } from "../services/SubcontractorRegion.service";
import SubcontractorRegionContext from "../contexts/SubcontractorRegion.context";
import { ISubcontractorRegion } from '../types/ISubcontractorRegion.interface';
import { message } from "antd";

export default ({ children }: any) => {

    const [subcontractorRegions, setSubcontractorRegions] = useState(undefined as ISubcontractorRegion[] | undefined);
    const [subcontractorRegionsSelection, setSubcontractorRegionsSelection] = useState(undefined as string[] | undefined);
    const [searching, setSearching] = useState(false as boolean);
    const [updating, setUpdating] = useState(false as boolean);

    const refresh = () => {

        setSearching(true);

        RetrieveSubcontractorRegions()
            .finally(() => setSearching(false))
            .then((response: ISubcontractorRegion[]) => {
                setSubcontractorRegions(response);
                select(response?.map(entity => entity.RegionId));
            })
    }

    const select = (selection: string[]) => {

        if (!Array.isArray(selection))
            return;

        const validation = selection.filter(value => value);
        setSubcontractorRegionsSelection(validation);
    }

    const update = () => {

        const notification = message.loading('Saving working regions in progress..')
            .then(_ => setUpdating(false))

        setUpdating(true);

        SaveSubcontractorRegions(subcontractorRegionsSelection)
            .finally(() => notification)
            .then(_ => message.success('Working regions have been saved'))
            .catch(exception => message.error('Failed saving working regions'))
    }

    useEffect(() => { refresh() }, []);

    return <SubcontractorRegionContext.Provider value={{ searching, subcontractorRegions, subcontractorRegionsSelection, refresh, select, updating, update }}>
        {children}
    </SubcontractorRegionContext.Provider>
};