import { useEffect, useState } from "react";
import { RetrieveEquipments } from '../services/Equipment.service';
import { IEquipment } from '../types/IEquipment.interface';
import EquipmentContext from "../contexts/Equipment.context";

export default ({ children }: any) => {

    const [equipments, setEquipments] = useState([] as IEquipment[]);
    const [searching, setSearching] = useState(false as boolean);

    const refresh = () => {
        setSearching(true);
        RetrieveEquipments().then((equipments: IEquipment[]) => setEquipments(equipments))
            .finally(() => setSearching(false))
    }

    useEffect(() => { refresh() }, []);

    return <EquipmentContext.Provider value={{ searching, equipments, refresh }}>
        {children}
    </EquipmentContext.Provider>
};