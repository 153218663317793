import { Breadcrumb } from "antd";
import { Camelize } from "../../utilities/string.common";

interface IProps {
    location: string;
}
export default (props: IProps) => {

    return <Breadcrumb>
        <Breadcrumb.Item key="home">
            Home
        </Breadcrumb.Item>
        {
            props.location && props.location.split('/').filter(item => item)
                .map((name, index) =>
                    <Breadcrumb.Item key={name}>
                        {Camelize(name)}
                    </Breadcrumb.Item>
                )
        }
    </Breadcrumb>
} 