import { LoadingOutlined } from '@ant-design/icons';
import { Icon } from '@fluentui/react';
import { Space } from 'antd';
import { IDocument } from '../../../types/IDocument.interface';
import styles from './DocumentStatusIcon.module.scss';

interface IProps {
    document: IDocument;
}

export default (props: IProps) => {

    if (!props.document)
        return <Space size='small'>
            <Icon iconName="Unknown" className={styles.icon} />
            Unknown
        </Space>

    else if (props.document.Pending)
        return <Space size='small'>
            <Icon iconName="Upload" className={styles.icon} />
            Awaiting Upload
        </Space>

    else if (props.document.AwaitingApproval)
        return <Space size='small'>
            <LoadingOutlined spin />
            Processing
        </Space>

    else if (props.document.Compliant)
        return <Space size='small'>
            <Icon iconName="Completed" className={styles.icon} style={{ color: 'green' }} />
            Compliant
        </Space>

    else if (props.document.Expiring)
        return <Space size='small'>
            <Icon iconName="Error" className={styles.icon} style={{ color: 'orange' }} />
            Expiring
        </Space>

    else if (props.document.Expired)
        return <Space size='small'>
            <Icon iconName="ErrorBadge" className={styles.icon} style={{ color: 'red' }} />
            Expired
        </Space>

    return <Space size='small'>
        <Icon iconName="Unknown" className={styles.icon} />
        Unknown
    </Space>
}