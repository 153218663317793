import { Button, Popover } from "antd";
import { IEquipmentType } from "../../../types/IEquipmentType.interface";

interface IProps {
    items?: IEquipmentType[]
}

export default (props: IProps) => {

    const content = Array.isArray(props.items) && props.items.map(item =>
        <div key={item.Id}>
            {item.Name}
        </div>)

    if (Array.isArray(props.items) && props.items.length)
        return <Popover placement="right" content={content} title="Equipment Types" trigger='click'>
            <Button size="small" type="link">
                <small>
                    {props.items.length} Equipment Types
                </small>
            </Button>
        </Popover>

    return null;
}