import { Alert, Divider } from "antd";
import { Content } from "antd/lib/layout/layout";
import OperatorFilter from "../../components/Operator/OperatorFilter/OperatorFilter.component";
import OperatorTableComponent from "../../components/Operator/OperatorTable/OperatorTable.component";
import OperatorRequestCreateButton from "../../components/OperatorRequest/OperatorRequestCreateButton/OperatorRequestCreateButton.component";
import OperatorContext from "../../contexts/Operator.context";
import OperatorRequestContext from "../../contexts/OperatorRequest.context";
import OperatorProvider from "../../providers/Operator.provider";
import OperatorRequestProvider from "../../providers/OperatorRequest.provider";

export default () => {
    return <OperatorProvider>
        <OperatorRequestProvider>
            <OperatorRequestContext.Consumer>
                {
                    (requestContext) => <OperatorContext.Consumer>
                        {
                            (context) => {

                                const refresh = () => {
                                    requestContext.refresh();
                                    context.refresh()
                                }

                                return <Content>
                                    <OperatorFilter
                                        refresh={refresh}
                                        searching={requestContext.searching || context.searching}>

                                        <OperatorRequestCreateButton
                                            create={requestContext.create}
                                            creating={requestContext.creating}
                                            searching={requestContext.searching || context.searching} />
                                    </OperatorFilter>
                                    <Divider />
                                    <OperatorTableComponent
                                        refresh={refresh}
                                        searching={requestContext.searching || context.searching}
                                        operators={[...requestContext.requests, ...context.operators]} />
                                </Content>
                            }
                        }
                    </OperatorContext.Consumer>
                }
            </OperatorRequestContext.Consumer>
        </OperatorRequestProvider>
    </OperatorProvider>
}