import UserAgreementProvider from '../../providers/UserAgreement.provider';
import UserAgreement from '../UserAgreement/UserAgreementPage';
import MenuNavigation from '../../common/components/MenuNavigation/MenuNavigation.component';
import UserAgreementContext from '../../contexts/UserAgreement.context';
import LoadingComponent from '../../common/components/LoadingComponent/Loading.component';
import styles from './App.module.scss';

import 'antd/dist/antd.css';
import { useLocation } from 'react-router-dom';
import ApplicationBreadcrumb from '../../common/components/ApplicationBreadcrumb/ApplicationBreadcrumb.component';
import ApplicationRoutes from '../../routes/Application.routes';
import DevelopmentWarningComponent from '../../common/components/DevelopmentWarning/DevelopmentWarning.component';

const App = () => {

  const location = useLocation();

  return <UserAgreementProvider>
    <UserAgreementContext.Consumer>
      {
        ({ searching, agreements }: any) => {

          if (searching)
            return <LoadingComponent fullScreen={true} message='Loading user agreements, please wait ...' />

          else if (Array.isArray(agreements) && agreements.length)
            return <UserAgreement />

          return <>
            <DevelopmentWarningComponent />
            <MenuNavigation />
            <div className={styles.breadcrumbWrapper}>
              <ApplicationBreadcrumb location={location.pathname} />
            </div>
            <div className={styles.container}>
              <ApplicationRoutes />
            </div>
          </>
        }
      }
    </UserAgreementContext.Consumer>
  </UserAgreementProvider>
}

export default App;
