import { v4 as uuid } from "uuid";
import { ISubcontractorRegion } from '../types/ISubcontractorRegion.interface';
import { regions } from "./Region.service";

let collection: ISubcontractorRegion[] =
    [
        {
            Id: uuid(),
            RegionId: regions[2].Id,
            RegionName: regions[2].Name
        }
    ]

export function RetrieveSubcontractorRegions(): Promise<ISubcontractorRegion[]> {
    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => [...collection]);
}

export function SaveSubcontractorRegions(selection: string[] | undefined): Promise<boolean> {

    if (!Array.isArray(selection))
        collection = [];

    else if (selection.length === regions.length)
        return new Promise((resolve, reject) => {
            throw new Error("Maximum number of regions reached");
        });

    else collection = regions.filter(region => selection?.includes(region.Id))
        .map(region => ({
            Id: uuid(),
            RegionId: region.Id,
            RegionName: region.Name
        }))

    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => true);
}