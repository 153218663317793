import { useState, useEffect } from 'react';
import { IEquipmentType } from '../../../types/IEquipmentType.interface';
import { RetrieveEquipmentTypes } from "../../../services/EquipmentType.service";
import { Select } from 'antd';
import EquipmentTypePopup from '../EquipmentTypePopup/EquipmentTypePopup.component';

interface IPropsEvents {
    onSelect: (selection: IEquipmentType[]) => void;
}

interface IProps extends IPropsEvents {
    categoryId: string;
    disabled?: boolean;
}

export default (props: IProps) => {

    const [searching, setSearching] = useState(false);
    const [options, setOptions] = useState(undefined as undefined | IEquipmentType[]);
    const [selection, setSelection] = useState(undefined as undefined | string[]);

    const reset = () => {
        setOptions(undefined);
        setSelection(undefined);
    }

    useEffect(() => {

        reset();

        if (!props.categoryId)
            return;

        setSearching(true);

        RetrieveEquipmentTypes(props.categoryId)
            .finally(() => setSearching(false))
            .then(response => !Array.isArray(response)
                ? setOptions(undefined)
                : setOptions(response));

    }, [props.categoryId]);


    const sorting = (o: IEquipmentType, x: IEquipmentType) => 
        o.SubcategoryName > x.SubcategoryName ? -1 : 1;

    const filtering = (query: string, option: any): boolean => {

        if (!query)
            return true;

        else if (!option)
            return false;

        else if (option.label?.toLowerCase().includes(query.toLowerCase()))
            return true;

        else if (option.children?.toLowerCase().includes(query.toLowerCase()))
            return true;

        return false;
    }

    return <>
        <Select allowClear={true} filterOption={filtering} maxTagCount={1} showArrow={true} loading={searching} disabled={searching || props.disabled} mode="multiple" onChange={setSelection} filterSort={sorting}>
            {
                options && options.map((item) => item.SubcategoryName).filter((value, index, self) => self.indexOf(value) === index)
                    .map(subcategoryName =>
                        <Select.OptGroup key={subcategoryName} label={subcategoryName}>
                            {
                                options.filter(option => option.SubcategoryName === subcategoryName).map(option =>
                                    <Select.Option key={option.Id} value={option.Id}>
                                        {option.Name}
                                    </Select.Option>)
                            }
                        </Select.OptGroup>)
            }
        </Select>
        <EquipmentTypePopup items={options?.filter(option => selection?.includes(option.Id))} />
    </>
}