import { ClearOutlined, CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Space } from "antd"
import { IOperatorRequest } from "../../../types/IOperatorRequest.interface";

interface IPropsEvents {
    create: (request: IOperatorRequest) => void;
    cancel: () => void;
}

interface IProps extends IPropsEvents {
    children?: any;
}

export default (props: IProps) => {

    const [form] = Form.useForm();

    const finish = (request: IOperatorRequest) => 
        props.create(request);

    const reset = (value: any) => {
        form.resetFields();
    }


    return <Form onReset={reset} id="operatorRequestCreate" layout="vertical" form={form} onFinish={finish} >
        <Form.Item name="Name" label="Name" rules={[{ required: true, message: 'Missing name' }]}>
            <Input />
        </Form.Item>
        <Form.Item name="PhoneNumber" label="Phone Number" rules={[{ required: true, message: 'Missing phone number' }]}>
            <Input />
        </Form.Item>
        <Form.Item name="Email" label="E-mail" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }]}>
            <Input />
        </Form.Item>

        <Row justify="end">
            <Col >
                <Space>
                    <Button htmlType="button" onClick={props.cancel} icon={<CloseOutlined />}>
                        Close
                    </Button>
                    <Button htmlType="button" onClick={reset} icon={<ClearOutlined />}>
                        Reset
                    </Button>
                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                        Save
                    </Button>
                </Space>
            </Col>
        </Row>
    </Form>
}