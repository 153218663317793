import { SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";

interface IPropsEvents {
    refresh: () => void;
}

interface IProps extends IPropsEvents {
    searching: boolean;
}

export default (props: IProps) => {
    return <Row justify="end">
        <Col>
            <Button type="primary" loading={props.searching} onClick={props.refresh} icon={<SyncOutlined />} >
                {props.searching ? 'Loading' : 'Refresh'}
            </Button>
        </Col>
    </Row>
}