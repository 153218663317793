import { Modal } from "antd";
import { IEquipmentRequest } from "../../../types/IEquipmentRequest.interface";
import EquipmentRequestCreateForm from "../EquipmentRequestCreateForm/EquipmentRequestCreateForm.component";

interface IPropsEvents {
    create: (request: IEquipmentRequest) => void;
    close: () => void;
}

interface IProps extends IPropsEvents {
    visibility: boolean;
    creating: boolean;
}

export default (props: IProps) => {

    const create = (request: IEquipmentRequest) =>
        props.create(request);

    return <Modal open={props.visibility} onCancel={props.close} maskClosable={false} centered={true} closable={true} footer={null}>
        <h3>
            New Equipment
        </h3>
        <EquipmentRequestCreateForm create={create} cancel={props.close}>
        </EquipmentRequestCreateForm>
    </Modal>
}