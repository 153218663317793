import { v4 as uuid } from "uuid";
import { IRegion } from "../types/IRegion.interface";

export const regions: IRegion[] = [
    {
        Id: uuid(),
        Name: 'Western Suburbs',
        Description: ['A', 'B', 'C', 'D'].map(name => `Western Suburb ${ name }`).join(', ')
    },
    {
        Id: uuid(),
        Name: 'Northern Suburbs',
        Description: ['A', 'B', 'C', 'D'].map(name => `Northern Suburb ${ name }`).join(', ')
    },
    {
        Id: uuid(),
        Name: 'Eastern Suburbs',
        Description: ['A', 'B', 'C', 'D'].map(name => `Eastern Suburb ${ name }`).join(', ')
    },
    {
        Id: uuid(),
        Name: 'Southern Suburbs',
        Description: ['A', 'B', 'C', 'D'].map(name => `Southern Suburb ${ name }`).join(', ')
    },
    {
        Id: uuid(),
        Name: 'Inner North',
        Description: ['A', 'B', 'C', 'D'].map(name => `Inner North Suburb ${ name }`).join(', ')
    },
    {
        Id: uuid(),
        Name: 'Inner East',
        Description: ['A', 'B', 'C', 'D'].map(name => `Inner East Suburb ${ name }`).join(', ')
    },
    {
        Id: uuid(),
        Name: 'Inner South',
        Description: ['A', 'B', 'C', 'D'].map(name => `Inner South Suburb ${ name }`).join(', ')
    },
    {
        Id: uuid(),
        Name: 'City Centre',
        Description: ['A', 'B', 'C', 'D'].map(name => `City Centre Suburb ${ name }`).join(', ')
    }
]


export function RetrieveRegions(): Promise<IRegion[]> {
    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => regions);
}