import { Icon } from "@fluentui/react";
import { Button, Dropdown, Menu, Space } from "antd";
import { IOperator } from "../../../types/IOperator.interface";

interface IPropsEvents {
    refresh: () => void;
}

interface IProps extends IPropsEvents {
    operator: IOperator;
}

export default (props: IProps) => {

    const menuComponent = <Menu>
        <Menu.Item onClick={props.refresh} disabled={true} >
            <Space>
                <Icon iconName="View" />
                View
            </Space>
        </Menu.Item>
    </Menu>

    return <Dropdown overlay={menuComponent} trigger={['click']}>
        <Button type="link">
            <Icon iconName="More" />
        </Button>
    </Dropdown>
}