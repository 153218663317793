import React, { Suspense } from 'react';
import App from './pages/App/App.page';
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client';
import AuthenticationProvider from './auth/authentication.provider';
import { withAuthenticationRequired, WithAuthenticationRequiredOptions } from '@auth0/auth0-react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import LoadingComponent from './common/components/LoadingComponent/Loading.component';
import './index.css';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';

initializeIcons();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const options: WithAuthenticationRequiredOptions = {
  onRedirecting: () => <LoadingComponent message='Validating your credentials, please wait ...' fullScreen={true} />
}

const ApplicationComponent = withAuthenticationRequired(App, options);

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Suspense fallback={<LoadingComponent message='Loading ...' fullScreen={true} />}>
        <BrowserRouter>
          <AuthenticationProvider>
            <ApplicationComponent />
          </AuthenticationProvider>
        </BrowserRouter>
      </Suspense>
    </React.StrictMode >
  </ErrorBoundary>
);