import { Space, Table } from "antd"
import { ColumnsType } from "antd/lib/table";
import { LoadingOutlined } from '@ant-design/icons';
import { Icon } from "@fluentui/react";
import styles from "./OperatorTable.module.scss"
import { IOperator } from "../../../types/IOperator.interface";
import OperatorActionButton from "../OperatorActionButton/OperatorActionButton.component";
import OperatorRequestActionButton from "../../OperatorRequest/OperatorRequestActionButton/OperatorRequestActionButton.component";
import { properties } from "../../../common/properties/Spin.properties";

interface IPropsEvents {
    refresh: () => void;
}

interface IProps extends IPropsEvents {
    searching: boolean;
    operators: IOperator[];
}

export default (props: IProps) => {



    const columns: ColumnsType<IOperator> = [
        {
            title: 'Name',
            dataIndex: 'Name'
        },
        {
            title: 'Phone Number',
            dataIndex: 'PhoneNumber'
        },
        {
            title: 'E-mail',
            dataIndex: 'Email'
        },
        {
            title: 'Status',
            render: record => <Space size='small'>
                {
                    record.Request
                        ? <LoadingOutlined />
                        : <Icon iconName="Completed" className={styles.icon} style={{ color: 'green' }} />
                }
                {
                    record.Request
                        ? 'Awaiting Approval'
                        : 'Active'
                }
            </Space>
        },
        {
            align: 'right',
            render: record => record.Request
                ? <OperatorRequestActionButton request={record} refresh={props.refresh} />
                : <OperatorActionButton operator={record} refresh={props.refresh} />
        }
    ];

    return <Table
        size="small"
        loading={{ ...properties, spinning: props.searching }}
        rowKey={o => o.Id}
        columns={columns}
        dataSource={props.operators}
    />
}

