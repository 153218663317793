import { useEffect, useState } from "react";
import { RetrieveRegions } from "../services/Region.service";
import RegionContext from "../contexts/Region.context";
import { IRegion } from "../types/IRegion.interface";

export default ({ children }: any) => {

    const [regions, setRegions] = useState(undefined as IRegion[] | undefined);
    const [searching, setSearching] = useState(false as boolean);

    const refresh = () => {
        setSearching(true);
        RetrieveRegions().then((response: IRegion[]) => setRegions(response))
            .finally(() => setSearching(false))
    }

    useEffect(() => { refresh() }, []);

    return <RegionContext.Provider value={{ searching, regions, refresh }}>
        {children}
    </RegionContext.Provider>
};