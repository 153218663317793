export function Camelize(value: string): string {

    if (!value)
        return '';

    const response = [];

    for (let word of value.split(' ')) {
        const transformation = word[0].toUpperCase() + word.slice(1).toLowerCase();
        response.push(transformation);
    }

    return response.join(' ');
}

export function Summarize(value: string, limit: number = 35): string {
    if (!value)
        return '';

    else if (value.length <= limit)
        return value;

    return value.substring(0, limit - 3) + '...';
}