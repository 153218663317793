

import { useEffect, useState } from "react";
import { RetrieveOperators } from '../services/Operator.service';
import OperatorContext from "../contexts/Operator.context";
import { IOperator } from "../types/IOperator.interface";

export default ({ children }: any) => {

    const [operators, setOperators] = useState([] as IOperator[]);
    const [searching, setSearching] = useState(false as boolean);

    const refresh = () => {
        setSearching(true);
        RetrieveOperators().then((operators: IOperator[]) => setOperators(operators))
            .finally(() => setSearching(false))
    }

    useEffect(() => { refresh() }, []);

    return <OperatorContext.Provider value={{ searching, operators, refresh }}>
        {children}
    </OperatorContext.Provider>
};