import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface IPropsEvents {
    update: () => void
}

interface IProps extends IPropsEvents {
    searching: boolean;
    updating: boolean;
}

export default (props: IProps) => {
    return <Button type="primary" loading={props.updating} disabled={props.searching} onClick={props.update} icon={<SaveOutlined />} >
        Save
    </Button>
}