import { Icon } from '@fluentui/react';
import style from './style.module.scss';

export default (props: any) => {

    if (props?.document) {

        if (['zip', 'tar', 'rar'].includes(props.document?.Extension))
            return <Icon className={style.icon} iconName="ZipFolder" />

        else if (props.document?.Extension == 'pdf')
            return <Icon className={style.icon} iconName="PDF" />

        else if (['doc', 'docx'].includes(props.document?.Extension))
            return <Icon className={style.icon} iconName="WordDocument" />

        else if (['png', 'jpg', 'bmp'].includes(props.document?.Extension))
            return <Icon className={style.icon} iconName="FileImage" />
    }

    return <Icon iconName="Page" />
}
