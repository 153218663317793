import { IDocument } from "../types/IDocument.interface";
import { v4 as uuid } from "uuid";

const documents: IDocument[] = [
    {
        Id: uuid(),
        TypeName: 'White/ Red Card',
        FileName: 'Document 1.jpg',
        Extension: 'jpg',
        ExpireDate: '2023-10-21',
        FileSize: 15,
        CreatedBy: 'fmena@southernplanthire.com.au',
        CreatedOn: '2020-11-25',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2021-03-20',
        Pending: true,
        AwaitingApproval: false,
        Compliant: false,
        Expiring: false,
        Expired: false
    },
    {
        Id: uuid(),
        TypeName: 'Drivers License',
        FileName: 'Document 2.png',
        Extension: 'png',
        ExpireDate: '2022-12-29',
        FileSize: 114,
        CreatedBy: 'fmena@southernplanthire.com.au',
        CreatedOn: '2019-11-25',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2020-03-20',
        Pending: false,
        AwaitingApproval: true,
        Compliant: false,
        Expiring: false,
        Expired: false
    },
    {
        Id: uuid(),
        TypeName: 'Train Awareness',
        FileName: 'Document 3.png',
        Extension: 'png',
        ExpireDate: '2019-12-29',
        FileSize: 94,
        CreatedBy: 'fmena@southernplanthire.com.au',
        CreatedOn: '2020-01-18',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2021-03-20',
        Pending: false,
        AwaitingApproval: false,
        Compliant: true,
        Expiring: false,
        Expired: false
    },
    {
        Id: uuid(),
        TypeName: 'Working with children',
        FileName: 'Document 4.pdf',
        Extension: 'pdf',
        ExpireDate: '2019-12-29',
        FileSize: 94,
        CreatedBy: 'fmena@southernplanthire.com.au',
        CreatedOn: '2021-01-18',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2021-03-20',
        Pending: false,
        AwaitingApproval: false,
        Compliant: true,
        Expiring: true,
        Expired: false
    },
    {
        Id: uuid(),
        TypeName: 'Operators Ticket',
        FileName: 'Document 5.png',
        Extension: 'png',
        ExpireDate: '2019-12-29',
        FileSize: 231,
        CreatedBy: 'fmena@southernplanthire.com.au',
        CreatedOn: '20201-10-11',
        LastModifiedBy: 'Jake Smith',
        LastModifiedOn: '2020-02-25',
        Pending: false,
        AwaitingApproval: false,
        Compliant: false,
        Expiring: false,
        Expired: true
    }
]

export function RetrieveDocuments(): Promise<IDocument[]> {
    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => documents);
}