import { ClearOutlined, CloseOutlined, EnterOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Space, Tabs } from "antd"
import TextArea from "antd/lib/input/TextArea";
import { ICategory } from "../../../types/ICategory.interface";
import { IEquipmentRequest } from "../../../types/IEquipmentRequest.interface";
import { IEquipmentType } from "../../../types/IEquipmentType.interface";
import CategorySelector from "../../Category/CategorySelector";
import EquipmentTypeSelector from "../../EquipmentType/EquipmentTypeSelector/EquipmentTypeSelector.component";

interface IPropsEvents {
    create: (request: IEquipmentRequest) => void;
    cancel: () => void;
}

interface IProps extends IPropsEvents {
    children?: any;
}

export default (props: IProps) => {

    const [form] = Form.useForm();

    const reset = (value: any) =>
        form.resetFields();

    const categorySelectionChange = (selection: ICategory) => {
        console.log('::: category selection change', selection);
        form.setFieldsValue({ CategoryId: selection?.Id, EquipmentTypeIds: undefined });
    }

    const equipmentTypeSelectionChange = (selection: IEquipmentType[]) => {
        console.log("🚀 ~ file: index.tsx ~ line 57 ~ select ~ selection", selection)

        // form.setFieldsValue({
        //     EquipmentTypeIds: Array.isArray(selection)
        //         ? selection.map(entity => entity.Id)
        //         : undefined
        // });
    }

    const categoryId = Form.useWatch('CategoryId', form);

    return <Form onReset={reset} id="equipmentRequestCreate" layout="vertical" form={form} onFinish={props.create} >
        <Form.Item name="CategoryId" label="Category" rules={[{ required: true, message: 'Missing category' }]}>
            <CategorySelector onSelect={categorySelectionChange} />
        </Form.Item>
        <Form.Item name="EquipmentTypeIds" label="Equipment Type">
            <EquipmentTypeSelector disabled={!categoryId} categoryId={categoryId} onSelect={equipmentTypeSelectionChange} />
        </Form.Item>
        <Form.Item name="RegistrationNumber" label="Registration">
            <Input />
        </Form.Item>
        <Form.Item name="Description" label="Description" >
            <TextArea rows={3} placeholder="Description" maxLength={250} />
        </Form.Item>

        <Row justify="end">
            <Col >
                <Space>
                    <Button htmlType="button" onClick={props.cancel} icon={<CloseOutlined />}>
                        Close
                    </Button>
                    <Button htmlType="button" onClick={reset} icon={<ClearOutlined />}>
                        Reset
                    </Button>
                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                        Save
                    </Button>
                </Space>
            </Col>
        </Row>
    </Form>
}