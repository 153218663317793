import { Icon } from "@fluentui/react";
import { Button, Dropdown, Menu, Space } from 'antd';
import { IQuestionary } from "../../../types/IQuestionary.interface";

interface IPropsEvents {
    refresh: (questionary: IQuestionary) => void;
}

interface IProps extends IPropsEvents {
    questionary: IQuestionary;
}

export default (props: IProps) => {

    const view = () => {
        console.log('🚀 view', props.questionary);
    }

    const update = () => {
        console.log('🚀 update', props.questionary);
        props.refresh(props.questionary);
    }

    const activity = () => {
        console.log('🚀 activity', props.questionary);
    }

    const menuComponent = <Menu>
        <Menu.Item onClick={view}>
            <Space>
                <Icon iconName="View" />
                View
            </Space>
        </Menu.Item>
        <Menu.Item onClick={update}>
            <Space>
                <Icon iconName="Edit" />
                Update
            </Space>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={activity}>
            <Space>
                <Icon iconName="ActivityFeed" />
                History
            </Space>
        </Menu.Item>
    </Menu>

    return <Dropdown overlay={menuComponent} trigger={['click']}>
        <Button type="link">
            <Icon iconName="More" />
        </Button>
    </Dropdown>
}