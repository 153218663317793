import { Divider } from 'antd';
import DocumentTable from "../../components/Document/DocumentTable/DocumentTable.component"
import SubcontractorDocumentContext from '../../contexts/SubcontractorDocument.context';
import SubcontractorDocumentProvider from '../../providers/SubcontractorDocument.provider';
import SubcontractorDocumentFilter from '../../components/SubcontractorDocument/SubcontractorDocumentFilter/SubcontractorDocumentFilter.component';
import { IDocument } from '../../types/IDocument.interface';
import { Content } from 'antd/lib/layout/layout';

export default () => {

    const upload = (document: IDocument) => {
        console.log(':: 🚀 upload', document.TypeName);
    }

    const download = (document: IDocument) => {
        console.log(':: 🚀 download', document.TypeName);
    }

    const activity = (document: IDocument) => {
        console.log(':: 🚀 activity', document.TypeName);
    }

    return <SubcontractorDocumentProvider>
        <SubcontractorDocumentContext.Consumer>
            {
                ({ searching, documents, refresh }) => {
                    return <Content>
                        <SubcontractorDocumentFilter searching={searching} refresh={refresh} />
                        <Divider />
                        <DocumentTable searching={searching} documents={documents} refresh={refresh} />
                    </Content>
                }
            }
        </SubcontractorDocumentContext.Consumer>
    </SubcontractorDocumentProvider>
}