import { Alert, Divider, message } from 'antd';
import { Content } from "antd/lib/layout/layout";
import EquipmentFilter from "../../components/Equipment/EquipmentFilter/EquipmentFilter.component";
import EquipmentTable from "../../components/Equipment/EquipmentTable/EquipmentTable.component";
import EquipmentRequestCreateButton from "../../components/EquipmentRequest/EquipmentRequestCreateButton/EquipmentRequestCreateButton.component";
import EquipmentContext from "../../contexts/Equipment.context";
import EquipmentRequestContext from "../../contexts/EquipmentRequest.context";
import EquipmentProvider from "../../providers/Equipment.provider";
import EquipmentRequestProvider from "../../providers/EquipmentRequest.provider";

export default () => {
    return <EquipmentProvider>
        <EquipmentRequestProvider>
            <EquipmentRequestContext.Consumer>
                {
                    (requestContext) =>
                        <EquipmentContext.Consumer>
                            {
                                (context) => {

                                    const refresh = () => {
                                        requestContext.refresh();
                                        context.refresh();
                                    }

                                    return <Content>
                                        <EquipmentFilter
                                            refresh={refresh}
                                            searching={requestContext.searching || context.searching} >

                                            <EquipmentRequestCreateButton
                                                create={requestContext.create}
                                                creating={requestContext.creating}
                                                searching={requestContext.searching} />
                                        </EquipmentFilter>
                                        <Divider />
                                        <EquipmentTable
                                            refresh={refresh}
                                            searching={requestContext.searching || context.searching}
                                            equipments={[...requestContext.requests, ...context.equipments]} />
                                    </Content>
                                }
                            }
                        </EquipmentContext.Consumer>
                }
            </EquipmentRequestContext.Consumer>
        </EquipmentRequestProvider>
    </EquipmentProvider>
}