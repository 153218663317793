import { useEffect, useState } from "react";
import { IDocument } from "../types/IDocument.interface";
import { RetrieveDocuments } from "../services/OperatorDocument.service";
import OperatorDocumentContext from '../contexts/OperatorDocument.context';

export default ({ children }: any) => {

    const [documents, setDocuments] = useState(undefined as IDocument[] | undefined);
    const [searching, setSearching] = useState(false as boolean);

    const refresh = () => {
        setSearching(true);
        RetrieveDocuments().then((documents: IDocument[]) => setDocuments(documents))
            .finally(() => setSearching(false))
    }

    useEffect(() => { refresh() }, []);

    return <OperatorDocumentContext.Provider value={{ searching, documents, refresh }}>
        {children}
    </OperatorDocumentContext.Provider>
};