import { Select } from "antd"
import { useEffect, useState } from "react";
import { RetrieveCategories } from "../../../services/Category.service";
import { ICategory } from "../../../types/ICategory.interface";

interface IPropsEvent {
    onSelect: (selection: ICategory) => void;
}

interface IProps extends IPropsEvent {

}

export default (props: IProps) => {

    const [searching, setSearching] = useState(false);
    const [categories, setCategories] = useState(undefined as undefined | ICategory[]);

    useEffect(() => {
        setSearching(true);
        RetrieveCategories()
            .finally(() => setSearching(false))
            .then(response => setCategories(response));
    }, []);

    const handleChange = (value: string) => {

        if (!Array.isArray(categories))
            return;

        const category = categories.find(x => x.Id === value);

        if (category)
            props.onSelect(category);
    }

    return <Select loading={searching} disabled={searching} onChange={handleChange} placeholder={'Select a category'}>
        {
            categories && categories.map(category =>
                <Select.Option key={category.Id} value={category.Id}>
                    {category.Name}
                </Select.Option>)
        }
    </Select>
}