import { useEffect, useState } from "react";
import UserAgreementContext from "../contexts/UserAgreement.context";
import { RetrieveUserAgreements, AcceptUserAgreement } from "../services/UserAgreement.service";
import { IUserAgreement } from "../types/IUserAgreement.interface";

export default ({ children }: any) => {

    const [agreements, setAgreements] = useState(undefined as IUserAgreement[] | undefined);
    const [searching, setSearching] = useState(false as boolean);
    const [accepting, setAccepting] = useState(false as boolean);

    useEffect(() => {
        setSearching(true);
        RetrieveUserAgreements()
            .finally(() => setSearching(false))
            .then((agreements: IUserAgreement[]) => setAgreements(agreements))
    }, []);

    const accept = () => {
        if (!Array.isArray(agreements))
            return;

        setAccepting(true);
        const agreementIds = agreements.map(agreement => agreement.Id);

        AcceptUserAgreement(agreementIds)
            .finally(() => setAccepting(false))
            .then((response: boolean) => {
                if (!response)
                    return;

                setAgreements(undefined);
            });
    }

    return <UserAgreementContext.Provider value={{ searching, agreements, accept, accepting }}>
        {children}
    </UserAgreementContext.Provider>
};