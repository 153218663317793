import { IEquipmentType } from "../types/IEquipmentType.interface";

export const equipmentTypes: IEquipmentType[] = 
[
    {
        "Id": "86B68CF2-D006-4802-A2FB-800D708012C1",
        "Name": "Auger",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5D0A93A4-1468-490E-9E3E-D0D302FA619F",
        "Name": "Backhoe",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "05824249-6562-46CB-91DA-C0ECADFFA146",
        "Name": "Backhoe \/ Single Axle Tipper",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "CE07072F-F68C-4167-8D3A-4DA8AFE2ED81",
        "Name": "Backhoe \/ Tandem Combo",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "8FCEB9C1-AD2B-4AF8-8EDC-57788A4A6FBB",
        "Name": "Backhoe Breaker Daily",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "7FED7ABB-A02A-4A3A-AB2E-DFCD004EE049",
        "Name": "Backhoe Penalty Rate",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5CD55922-05D4-42C2-95BD-1E7CC09AFB26",
        "Name": "Extend a Hoe",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "B1F7464B-4664-4A4E-8352-AB9962640123",
        "Name": "Forks",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "387DB95B-F0BC-4459-866D-CE91EC79CF3C",
        "Name": "Rippers",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "57159C28-A13E-4BB6-A3A8-18B654B2D86E",
        "Name": "Smudger",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "11D9DB4C-1323-4CB9-B860-38A2C282C8B5",
        "Name": "Trencher",
        "SubcategoryId": "F035E0FF-47D9-49FD-A6C9-74A6F611D74F",
        "SubcategoryName": "Backhoe",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "3D793BA5-19A5-495A-9913-C0BC2A4A8D73",
        "Name": "Auger",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D71411A4-4211-408E-B9B3-DD545CB2B2E9",
        "Name": "Bobcat",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "7219C710-EB5E-4225-BB49-349CA99C5DE0",
        "Name": "Bobcat \/  Tandem Tipper",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "DDEF4A11-8F4F-4D4F-A7B4-5D86C09B0D8B",
        "Name": "Bobcat \/ Single Axle Tipper",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "7C98106F-3A4B-4604-B85E-6DE74BAE8114",
        "Name": "Bobcat \/ Truck & Trailer",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "13C1158E-A685-4439-A250-253CB95855ED",
        "Name": "Bobcat Penalty Rate",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "2F1D70E7-04A0-4ED6-B15F-A5B387F766D4",
        "Name": "Broom",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F41608DD-E40D-4B5F-812E-C68AC162B69E",
        "Name": "Forks",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "DF58DF77-2A46-4A7D-81E6-2DF0A8CFC714",
        "Name": "Harley Rake",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "4E0B5CBF-9A9C-4902-9148-ADE4552C11BB",
        "Name": "Profiler",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "08AACA4B-2602-4BF8-8223-96935817C6BD",
        "Name": "Smudger",
        "SubcategoryId": "3CF3C9E2-BD5C-434B-932E-8D4A7197C8E9",
        "SubcategoryName": "Bobcat",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "2079CCF2-9BB7-43C0-934D-1C9C6CAB9849",
        "Name": "815 Compactor",
        "SubcategoryId": "79914C19-07EF-4F88-99B8-4A6466A8813F",
        "SubcategoryName": "Compactor",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "0500D54C-E90F-4062-8C77-10CF62D94312",
        "Name": "Crane Truck",
        "SubcategoryId": "0AE9CF7A-5DC2-470A-8EBC-B468B70E5777",
        "SubcategoryName": "Crane Truck",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "858AB3C5-5308-4BAA-B6DD-5404ABF9ABDA",
        "Name": "Dingo",
        "SubcategoryId": "DA966E68-9288-4077-9248-0CD833C19DD7",
        "SubcategoryName": "Dingo",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "278B63E1-9D91-4944-B7E1-855E82BB4951",
        "Name": "D4 Dozer",
        "SubcategoryId": "D42924FD-0F2F-4E8E-83BA-79F28C8F2B6C",
        "SubcategoryName": "Dozer",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "07DD53C0-235E-4651-BAF7-4BDA7764B390",
        "Name": "D5 Dozer",
        "SubcategoryId": "D42924FD-0F2F-4E8E-83BA-79F28C8F2B6C",
        "SubcategoryName": "Dozer",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "53D3EC7D-F6D6-4CE9-BDB4-FD4EA897818D",
        "Name": "D6 Dozer",
        "SubcategoryId": "D42924FD-0F2F-4E8E-83BA-79F28C8F2B6C",
        "SubcategoryName": "Dozer",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "7B043961-12D8-49CC-99B6-9A8050096BA0",
        "Name": "D7 Dozer",
        "SubcategoryId": "D42924FD-0F2F-4E8E-83BA-79F28C8F2B6C",
        "SubcategoryName": "Dozer",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "1896ACDA-B54B-4E73-9589-B634A3A04D4E",
        "Name": "D9 Dozer",
        "SubcategoryId": "D42924FD-0F2F-4E8E-83BA-79F28C8F2B6C",
        "SubcategoryName": "Dozer",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "78644EAB-C417-4E05-B1BA-85E0C3616171",
        "Name": "Dozer Penalty Rate",
        "SubcategoryId": "D42924FD-0F2F-4E8E-83BA-79F28C8F2B6C",
        "SubcategoryName": "Dozer",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6FA624A1-7AE2-4452-8094-9D2881FC66F6",
        "Name": "Swamp Dozer",
        "SubcategoryId": "D42924FD-0F2F-4E8E-83BA-79F28C8F2B6C",
        "SubcategoryName": "Dozer",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "4F9CCE95-C810-4885-89F6-0A71C1D4668B",
        "Name": "Cleaning",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "601E5F2D-9A6E-496D-9A6B-05546CE5B97D",
        "Name": "Dry Hire 1.5T Excavator ",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5A686B8D-31A8-4A9E-BFDD-FCDF777A3148",
        "Name": "Dry Hire 1.5T Twin Drum Roller",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "9CE7380F-54D4-4ADA-9290-DC97B479816D",
        "Name": "Dry Hire 12T Pad Foot Roller",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F083A8B6-73C8-4651-8B63-CA3DCA45F101",
        "Name": "Dry Hire 12T Smooth Drum Roller",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "1AC17AF9-321B-4BE6-B033-3EC8B1DD0222",
        "Name": "Dry Hire 14T Excavator ",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "1817074B-0BF9-4B5A-A452-2AE69B18B175",
        "Name": "Dry Hire 2.5T Excavator ",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F908140F-B4A2-460B-9E7E-EC65746AC7B5",
        "Name": "Dry Hire 20-25T Compaction Plate",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "2E1EB43F-ACD7-4E8E-9D7F-4E89AE80900E",
        "Name": "Dry Hire 20-25T Compaction Wheel",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5D27EAC6-1E9F-4636-B008-98FC8D85DBCD",
        "Name": "Dry Hire 25T Excavator",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C4EEEC65-B3F4-40D3-A535-9B0170509EB9",
        "Name": "Dry Hire 30T Dump Truck",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "891513A5-49DD-4007-8563-0F7549C46000",
        "Name": "Dry Hire 3T Twin Drum Roller",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "3F846B45-AAD5-4825-9E2D-D4B7BB9C5DC1",
        "Name": "Dry Hire 5T Excavator",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "B0CDC098-FC8A-4B7F-97CB-ADC9DC06337C",
        "Name": "Dry Hire 5T Pad Foot Roller",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F91CA96C-6064-4BE2-83EB-4D10F73C31C2",
        "Name": "Dry Hire 5T Smooth Drum Roller",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "7A35D55F-63FC-4DF3-9B49-F34D7EA5C842",
        "Name": "Dry Hire 7T Pad Foot Roller",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5F08FB69-F3C9-40B1-B36B-EB7DF13C56D4",
        "Name": "Dry Hire 7T Smooth Drum Roller",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5A7A1CCC-9C6F-42D9-848A-EA240DAFDB05",
        "Name": "Dry Hire 815 Compactor",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "E93C33E7-BA2E-4A13-84F5-3ABFF2F9503C",
        "Name": "Dry Hire 8T Excavator ",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D017291C-FC20-4133-B164-DE715E4BA531",
        "Name": "Dry Hire D65 Dozer",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "BC0AE77B-E0AF-4561-A962-777DB5A3CA59",
        "Name": "Dry Hire Positrack 100 HP ",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "3F1719AA-95BA-4A1A-BBC1-8C04F02F64CD",
        "Name": "Dry Hire Positrack 60-90 HP",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "B065024A-0363-430D-9F78-50A51547016B",
        "Name": "Dry Hire Ripper Tyne",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "3F6C7F03-DED8-4701-B03C-ECB8EA73958E",
        "Name": "Dry Hire Sweeper Attachment",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "1C393FDE-3158-420A-98D0-C71BF75DCDF0",
        "Name": "Dry Hire Trailer",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "605C8BD2-5DCF-49B0-99DA-C2CAE9DAE839",
        "Name": "Dry Hire Truck",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "DD9C82C8-3DD8-4D2A-896F-0C9603C1CB31",
        "Name": "Dry Hire Watercart",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "162EA4C3-8DEC-4C69-A6CE-714729662C82",
        "Name": "Dumper",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6E64EA0F-1A07-4148-AACE-64E58D00947C",
        "Name": "Fuel",
        "SubcategoryId": "175517EE-9E00-44BF-A3A7-719AACE122F2",
        "SubcategoryName": "Dry Hire",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "50429B2D-7975-477A-A7DA-CBE7317E5E95",
        "Name": "25T Dump Truck",
        "SubcategoryId": "CE59C42B-C37B-4EC4-B896-CE48B64792CF",
        "SubcategoryName": "Dump Truck",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "FEF9EDEC-5466-4AEE-826A-23365098E4E3",
        "Name": "30T Dump Truck",
        "SubcategoryId": "CE59C42B-C37B-4EC4-B896-CE48B64792CF",
        "SubcategoryName": "Dump Truck",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "91AD499E-7588-4FF4-87A7-7193508A7E04",
        "Name": "35T Dump Truck",
        "SubcategoryId": "CE59C42B-C37B-4EC4-B896-CE48B64792CF",
        "SubcategoryName": "Dump Truck",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C9A1C83D-A3B4-4FA6-B19C-B87B3F526585",
        "Name": "<2T Auger",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "AC925212-C4EB-48D2-AA84-BC1BED40391A",
        "Name": "<2T Breaker",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "8BD88B23-55BF-4A5D-BCC3-3D55C2439170",
        "Name": "<2T Exc \/ Bobcat \/ Single Axle Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "90DFE8FD-735B-4A24-8AD5-E2D29BE277D3",
        "Name": "<2T Exc \/ Bobcat \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "EBCDF02E-BA38-4D95-A9A2-B1DCE4002410",
        "Name": "<2T Exc \/ Bobcat Combination",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D4248F76-EE09-4343-8784-1910C9C0FD9C",
        "Name": "<2T Exc \/ Positrack \/ Single Axle Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "22B3D225-3D53-41FA-9BC4-EBDE48DDAB71",
        "Name": "<2T Exc \/ Positrack \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C72215DC-C914-41CF-A614-BC6A33EE34C1",
        "Name": "<2T Exc \/ Positrack Combination",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "A706C798-0C46-4DAD-96B1-576AD16A68B0",
        "Name": "<2T Exc \/ Single Axle Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F0288C99-FC4C-4690-B407-476CCC374B70",
        "Name": "<2T Exc \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "E0F6320B-A9DD-46DD-8DC9-EE7CE69A1ECC",
        "Name": "<2T Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D370E3A8-B545-44E4-920F-57AAFB7D4A31",
        "Name": "<2T Rippers",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "E09942B2-93C2-4D11-BFE2-6B412031ACEF",
        "Name": "12T (10 - 13.4T) Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "81E71781-89AE-46FB-8748-BBC834D9961B",
        "Name": "12T (10 - 13.4T) Excavator + Breaker",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "7CF0D70F-C790-4D42-8E2B-8016069B8458",
        "Name": "12T Auger Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "EA153C4D-335E-4A04-8C6C-15E994315992",
        "Name": "12T Breaker Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "16CD97D0-7B74-43A5-8458-AB0DE2ED1C18",
        "Name": "12T Compaction Plate",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "550D0E5A-BD97-4228-B602-BD4F046A4471",
        "Name": "12T Compaction Wheel",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6A1F3A02-806E-44CC-9F16-384157D195FE",
        "Name": "12T Exc \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "89BCEDF1-221C-48FC-8D73-BAC672B772E2",
        "Name": "12T Grabs",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "BF2953BA-CF73-4317-9BF9-AB0AB548095D",
        "Name": "12T Rippers",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "4F608254-107C-40BE-9E99-E31D7C3CF4CD",
        "Name": "12T Sorting Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "22F9EEC8-A89F-446E-AC2D-AFB860328FF7",
        "Name": "12T Tilt Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "72FA166A-2914-4BCC-9BE0-266BD731DDEE",
        "Name": "15T (13.5 - 17.4) Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "38347A42-0B91-40C7-9AD8-1633185FE42B",
        "Name": "15T (13.5 - 17.4) Excavator + Breaker",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "B4B4607C-D802-490E-8D63-CB495B05690A",
        "Name": "15T Auger Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F2B45B8F-DBAD-4DB2-880C-C5D53F4A3CEA",
        "Name": "15T Breaker Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "841A0EFB-AA45-461C-A9BC-5A0A9878C150",
        "Name": "15T Compaction Plate",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "684D41A7-6336-49F8-BAF3-27CEF4D301B7",
        "Name": "15T Compaction Wheel",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "40B5AE0B-AB8D-4664-8926-60D779122705",
        "Name": "15T Core Barrell",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "8DDD1EFE-4A8C-4707-97E5-E5E6E440BDDB",
        "Name": "15T Exc \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "523A6BB9-B5D8-4FD5-8553-0C637D91E76C",
        "Name": "15T Grabs",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6D95CA74-1FD4-41CA-90FF-21406AAD8042",
        "Name": "15T Rippers",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D6F60735-2C7A-4DEF-8FFC-2982C6B415C2",
        "Name": "15T Sorting Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "10F0B46B-4C30-4494-9AF3-243B26F6F9DD",
        "Name": "15T Tilt Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "34AE5DEB-13A3-4332-BCC3-FE755BCDAD5B",
        "Name": "15Ton Exc & 8Ton Exc Combo",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "408345C0-F534-41C1-93A1-97F1457A32C2",
        "Name": "20T (17.5- 22.4) Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "567673CD-808E-4CE9-9539-D76038FF0D00",
        "Name": "20T (17.5- 22.4) Excavator + Breaker",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "8F49A345-F867-4A29-96BB-BE1816C605BE",
        "Name": "20T Auger Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "A025E229-D761-412F-B24B-4F43D2B14D24",
        "Name": "20T Breaker Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C7F5AAF9-DC1C-40A5-9D3B-80D598A0276C",
        "Name": "20T Compaction Plate",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "1A0289AB-3DF0-448F-B484-64B32C6AB46D",
        "Name": "20T Compaction Wheel",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "E6C54571-BC59-477F-9532-2CC2CFB48196",
        "Name": "20T Core Barrell",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "39920FF7-45DE-416B-80DB-AA47C3EE7177",
        "Name": "20T Grabs",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "10384407-FA5A-4502-9586-31E12C97F51F",
        "Name": "20T Rippers",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "4A364007-C2F5-4F69-AE2D-8EED73E8B9BB",
        "Name": "20T Sorting Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "75FEFFA6-EBA0-47CD-AE3D-4E135D67E162",
        "Name": "20T Tilt Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "A6447B97-6A6B-49AC-8C04-2492FAB819F8",
        "Name": "25T (22.5 - 27.4) Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C3FACAC3-2914-430E-AFFB-9D3DA86DCFB8",
        "Name": "25T (22.5 - 27.4) Excavator + Breaker",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6F93C2E7-C428-4F17-B43F-AADF415194AD",
        "Name": "25T Auger Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D487C7A5-7CB8-494B-A32D-BB0583D02BE5",
        "Name": "25T Breaker Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F2EFA218-7BFD-4028-85DD-B9A43EA04092",
        "Name": "25T Compaction Plate",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "B51E707D-7196-4648-938C-A63EABC7A9AD",
        "Name": "25T Compaction Wheel",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "FA7559C3-9AA2-4A41-AAEE-98C7F3B19CC1",
        "Name": "25T Core Barrell ",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F97F378F-8A8D-440B-A0E4-FAB1AED84CF4",
        "Name": "25T Grabs",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "37F88A0E-EE1B-4053-8D62-EA99E3E8639A",
        "Name": "25T Rippers",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "60C0DB99-4BBF-4D88-9CFB-C8189A29B4A1",
        "Name": "25T Sorting Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "27A03AAA-5154-49F7-9B52-FA6F5054FFA9",
        "Name": "25T Tilt Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "255BCBDD-CFEB-45ED-B3E2-FC88BBFB39F1",
        "Name": "30T Auger Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "9E2AEF1C-4C0E-430E-9663-E7FBB857D123",
        "Name": "30T Breaker Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "08396AC6-D3E6-4BF3-AC76-FF33C57BAB4C",
        "Name": "30T Compaction Plate",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6DBDD7E5-17FB-4C1B-B0DD-702D0F121F68",
        "Name": "30T Compaction Wheel",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "AB1C929D-14C0-4763-A62E-8592BDE02AB1",
        "Name": "30T Core Barrell",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "AD65959C-0221-4816-8FCF-22F4CC6DEA17",
        "Name": "30T Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6A94BF33-E751-4AF4-A16B-1EB8E25BAEC8",
        "Name": "30T Excavator + Breaker",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "BC7E7BFE-37AC-4A34-81A0-15FC30FA5D9A",
        "Name": "30T Grabs",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "74B15F77-96F9-44F0-80BC-8F50C8E06988",
        "Name": "30T Rippers",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "0ED21842-E337-482D-A2B1-2294D653F178",
        "Name": "30T Sorting Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "74ACEA82-B075-4659-BA6C-D391F38DF00E",
        "Name": "30T Tilt Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5BBD6609-988D-4A79-BA70-1A4CFBBB2EDF",
        "Name": "35T Breaker Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "8B2543A0-2371-490C-A09D-819864452032",
        "Name": "35T Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6E4B0493-A3C2-4465-99EC-CED17EC59574",
        "Name": "35T Excavator + Breaker ",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "A871B422-5968-4B58-8554-657AD5346356",
        "Name": "35T Sorting Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "3D10B209-A99B-4FA1-9E64-B5CCECAB5F75",
        "Name": "3T (2 - 3.9 T) Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F8952C42-2DF2-4D28-B499-217D91ED8DF1",
        "Name": "3T Auger",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F7B2D34D-C073-44CB-9D37-F4ABA63590D8",
        "Name": "3T Breaker",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5F835E91-269A-4ADA-A76B-F6D640ED40EF",
        "Name": "3T Exc \/ Bobcat \/ Single Axle Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "FB7AF1D0-B10D-4405-B06F-0156ADE95D7D",
        "Name": "3T Exc \/ Bobcat \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "32F0CDC5-3B46-4A21-A606-38E45FFEBFC0",
        "Name": "3T Exc \/ Bobcat Combination",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "416BE169-78DC-4381-86D3-826AFBD72DA1",
        "Name": "3T Exc \/ Positrack \/ Single Axle Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "46CDA454-F03A-4D8D-9BF2-BB755B6938D6",
        "Name": "3T Exc \/ Positrack \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D69B9332-F037-4B3C-94B8-4B3001CA8DF2",
        "Name": "3T Exc \/ Positrack Combination",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D5A404EC-A599-4DD5-9FD2-58121214DE58",
        "Name": "3T Exc \/ Single Axle Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D4A347DF-72BC-4CB5-AF9E-38775E6E16D3",
        "Name": "3T Exc \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "E0517CBE-34C3-480D-80B6-9B2E2384BB22",
        "Name": "3T Grabs",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6790C517-B5A2-419A-9097-DC5D3E708C76",
        "Name": "3T Rippers",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "71F7CE76-F5F5-48B5-9832-AC6C6C53D7A9",
        "Name": "40T Breaker Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "9E7EEC5E-8A40-4D1D-BAA0-656B2D60AF7F",
        "Name": "40T Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "2A8DCCE6-F1F8-446C-B752-9FBF251424D5",
        "Name": "40T Ripper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "49495C2E-F9EC-4D4D-A395-90C8295AC64F",
        "Name": "40T Sorting Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "3DF1D02B-B5C6-4FB0-9577-CE2091910808",
        "Name": "50T Breaker Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "E5118899-2A61-4E40-83B0-DD16C61F73DC",
        "Name": "50T Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D5AA8ADF-070F-438B-8CC2-4D5660ADD7F1",
        "Name": "50T Sorting Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F3C5644C-9822-4BB8-A69A-4D0D9D334A39",
        "Name": "5T (4 - 5.9 T) Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "20BF509D-D040-4791-8C4B-17FA595D6A35",
        "Name": "5T (4 - 5.9 T) Excavator + Breaker",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "A39CD179-CEF2-4F7E-B8D3-3A872BE8EE1E",
        "Name": "5T Auger",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "95A9A7BD-F8B7-4401-9407-807AFCBBA1B5",
        "Name": "5T Breaker",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "7302E0D7-6BA7-42F7-8773-90C393167D3A",
        "Name": "5T Compaction Plate",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "EAB8293D-A28E-426B-911C-A8E4D477275E",
        "Name": "5T Compaction Wheel",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "3A7CFFD3-A2EF-4D74-A698-088C679DFD41",
        "Name": "5T Exc \/ Bobcat \/ Single Axle Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "1BC39062-C85C-4672-9AA9-8056FEC89E2A",
        "Name": "5T Exc \/ Bobcat \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "905A74E5-210F-4FAF-911E-F1B580D80F31",
        "Name": "5T Exc \/ Bobcat Combination",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "1DDCCA6D-5226-4E09-9C86-20863ECD3BB8",
        "Name": "5T Exc \/ Positrack \/ Single Axle Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "0A7AA858-E0BA-4A71-A373-6F8A1EA021E7",
        "Name": "5T Exc \/ Positrack \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "8524E991-25BA-4E94-98EF-3A4D91DBEF16",
        "Name": "5T Exc \/ Positrack Combination",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "69226672-ECCF-43E2-9E0F-D0D45A1BCB1F",
        "Name": "5T Exc \/ Single Axle Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "AE0F10B2-3E0F-4DED-949D-F0D8737B3BD4",
        "Name": "5T Exc \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "418C67DC-C8FB-4EA4-A11E-67F28ED4E425",
        "Name": "5T Grabs",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "367590C7-3C44-46E0-AF00-0A6A315F992B",
        "Name": "5T Rippers",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "CE57B7D2-F624-42A4-A7B7-BCDDDEA51D7E",
        "Name": "5T Sorting Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "DF6C36AC-CDA0-4452-885A-6818732DE344",
        "Name": "5T Tilt Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "06D40208-0032-4CFC-B7C1-952FA47C7A59",
        "Name": "8T (6 - 9.9T) Excavator",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "0B82721C-BC9E-4BF3-AF23-D3AF93150B57",
        "Name": "8T (6 - 9.9T) Excavator + Breaker",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F982BC9B-FF48-4EA5-9DE8-94FF719A1787",
        "Name": "8T (6 - 9.9T) Excavator + Grabs",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "9DBBE088-08B5-491E-8FF6-9804A5426CBB",
        "Name": "8T Auger Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "30A8405C-7768-4116-A2F3-4122C4CBBBAD",
        "Name": "8T Breaker Daily",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6A503716-0162-4421-9DCC-A055EECD31B2",
        "Name": "8T Compaction Plate",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "447F8509-6C38-4104-91D3-A593E73AD1DF",
        "Name": "8T Compaction Wheel",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5F0CA0D9-FF90-407C-ACAE-E79DB33EA561",
        "Name": "8T Exc \/ 2T Exc \/ Positrack ",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6157FF2D-1416-47FD-B8A0-868F13E4049C",
        "Name": "8T Exc \/ 2T Exc \/ Single Axle Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "FCB293C1-3F9E-4F80-97BD-5BA4CE81F326",
        "Name": "8T Exc \/ 2T Exc Combination",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C1B1E74F-864A-4D3B-9B4F-367C39E64426",
        "Name": "8T Exc \/ Bobcat \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "143F2BDD-D334-4BF6-8A0C-23788E97D12F",
        "Name": "8T Exc \/ Bobcat Combination",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "45E909D3-A948-45DD-AEB4-8356CCEEEB36",
        "Name": "8T Exc \/ Positrack \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "191F0418-04B7-4C53-915F-69E2EFC03D45",
        "Name": "8T Exc \/ Positrack Combination",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "68305CF3-6A40-488B-8364-C66DC1703410",
        "Name": "8T Exc \/ Tandem Tipper",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "272308C0-0342-43C9-B7A3-CD2D3A6C99F1",
        "Name": "8T Exc \/ Truck & Trailer",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C892673C-D1EE-4ECC-87BF-BBE930E961F2",
        "Name": "8T Grabs",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "3FCEC564-9173-4805-8D29-0A7F472A5626",
        "Name": "8T Rippers",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "DE9AEA2F-157D-480B-91E2-BEE543764ABB",
        "Name": "8T Sorting Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F29686D5-17A2-4FC2-9479-8916A1F75248",
        "Name": "8T Tilt Bucket",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "3751F5BE-DF3A-46E2-B3E8-74E19180ABEA",
        "Name": "Excavator Penalty Rate",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "EF3B67F9-E0A0-4F91-ACDD-45BB4F255574",
        "Name": "GPS",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "1AE757D0-ABA2-4352-AEBF-190FE554A112",
        "Name": "Grader Blade",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "9817F8A1-4AF2-419B-9A56-73DD52D6E7C9",
        "Name": "Hydraulic Screener",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "4EB6DCE4-A2F5-4640-A3FC-F6FFF2566AC4",
        "Name": "Laser",
        "SubcategoryId": "82D93898-9764-4C6D-840F-40E30C428DCC",
        "SubcategoryName": "Excavators",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "DB0E4E4A-EA23-4605-9995-E92848E6A954",
        "Name": "10ft Blade Grader",
        "SubcategoryId": "9524BABD-CF55-4D4C-A081-83E664A5BEA1",
        "SubcategoryName": "Grader",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "25E157A7-5B8A-44E7-9B26-1F840A0539DA",
        "Name": "12ft Blade Grader",
        "SubcategoryId": "9524BABD-CF55-4D4C-A081-83E664A5BEA1",
        "SubcategoryName": "Grader",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "47C5264D-5DFC-4311-A21B-623C5E9C1A22",
        "Name": "14ft Blade Grader",
        "SubcategoryId": "9524BABD-CF55-4D4C-A081-83E664A5BEA1",
        "SubcategoryName": "Grader",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "07F83DA9-6064-4534-A35D-979C5A2C6E20",
        "Name": "16ft Blade Grader",
        "SubcategoryId": "9524BABD-CF55-4D4C-A081-83E664A5BEA1",
        "SubcategoryName": "Grader",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5642DDE4-F971-47A9-9B41-F34ED0636DD7",
        "Name": "GPS",
        "SubcategoryId": "9524BABD-CF55-4D4C-A081-83E664A5BEA1",
        "SubcategoryName": "Grader",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "0031273D-AD2B-49A8-9B01-D25467A700D5",
        "Name": "Grader Penalty Rate",
        "SubcategoryId": "9524BABD-CF55-4D4C-A081-83E664A5BEA1",
        "SubcategoryName": "Grader",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F11AFB66-79E3-4BD2-8385-7C369ADAAAD0",
        "Name": "Laser",
        "SubcategoryId": "9524BABD-CF55-4D4C-A081-83E664A5BEA1",
        "SubcategoryName": "Grader",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5FED19EC-3040-4FC4-BD0F-52D9E1CECB45",
        "Name": "UTS",
        "SubcategoryId": "9524BABD-CF55-4D4C-A081-83E664A5BEA1",
        "SubcategoryName": "Grader",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "4C773B72-978D-4AFB-B124-12E557F95953",
        "Name": "5T Exc \/ Twin Steer Tipper ",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "B072CB89-30CD-4D5F-9D53-0939E0BAD2A2",
        "Name": "Chisel Plow",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C80BA663-A538-462F-9309-53F546BF0150",
        "Name": "Crane",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "35845A6E-B188-4AA2-B173-BF1DA174B38B",
        "Name": "Dogman",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "A5FA7A2E-2799-47C2-90E6-92F9BAD2623F",
        "Name": "Franna",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "E0D155F7-6133-443B-8B19-884826060047",
        "Name": "Labourer",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "7BCDB209-D0DC-4B2C-82B3-661313707984",
        "Name": "Loader",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6026C489-749F-44D4-95ED-3EFDD14139B6",
        "Name": "Manitou",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "AA4C3FB9-9715-49A6-9311-3624828878D1",
        "Name": "Operator",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "8CF26AD8-1F1B-4361-87A3-A4E8294B2C49",
        "Name": "Tandem Tag Along",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "EDE5DB59-7E28-4DA8-84F7-208B61AF8601",
        "Name": "Trencher",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "7D79445A-DD56-4F75-BDEF-4E0A150DE75E",
        "Name": "Vacuum Truck",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "140A66FC-2368-4275-86A1-7C5E2C0FCFFD",
        "Name": "Wet Hire 13T Smooth Drum Roller ",
        "SubcategoryId": "E0DFA2EB-72BD-464A-B545-2AC81D5F2FAC",
        "SubcategoryName": "Other",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "E9122B4F-B8B3-449F-9A86-51A33931E67E",
        "Name": "Auger",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "52FDF90A-33FB-40F7-80FD-B2F5FCB73B2C",
        "Name": "Broom",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "56B5F9A7-1DA0-4A41-85BA-B1388CCFEF5B",
        "Name": "Compaction Plate",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "45317491-6B33-4394-8E5A-4A2B329A6F1D",
        "Name": "Forks",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "74616092-14C0-4701-8F43-CFBB9302A016",
        "Name": "GPS",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "60C6F997-AE8D-480B-8758-513E744714B8",
        "Name": "Grader Blade",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "38AF3A59-C5E1-430B-9BCB-CBF88579C176",
        "Name": "Harley Rake",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "A75983FB-5732-446A-947A-E41A9FD57B74",
        "Name": "Laser",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "22CCC4C6-6A2B-4B4E-A32D-25FB82189BD6",
        "Name": "Positrack < 60 HP",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6F9BF547-DC11-42E9-BB68-49C42B61B003",
        "Name": "Positrack < 60 HP \/ Single Axle Tipper",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "2A14899A-C949-40C2-995D-CA4E5AC566A1",
        "Name": "Positrack < 60 HP \/ Tandem Tipper",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "E0275DA2-8ADD-4FD5-9314-3769256ADDA5",
        "Name": "Positrack < 60 HP \/ Truck & Trailer",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C45A10BC-E5B0-46DB-9407-EDA56A778C54",
        "Name": "Positrack > 90 HP",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "835E0DB8-9731-4B50-B3F6-CAA59B553ABE",
        "Name": "Positrack > 90 HP \/ Single Axle Tipper",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "8141ED6B-8BF6-47D3-9E79-08ECF5544B6E",
        "Name": "Positrack > 90 HP \/ Tandem Tipper",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "60C66A5A-36FA-49B8-BDA9-74E9ACF27471",
        "Name": "Positrack > 90 HP \/ Truck & Trailer",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "8C66BF08-4D16-4D3F-8542-A2CBB6697956",
        "Name": "Positrack 60-90 HP",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "087FA0DB-B19B-4566-9B89-25C13F23670D",
        "Name": "Positrack 60-90 HP \/ Single Axle Tipper",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "55524651-C8BD-4218-8014-5B5F542E2FA2",
        "Name": "Positrack 60-90 HP \/ Tandem Tipper",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F4487A95-80AD-47F3-A813-7854E8AE0349",
        "Name": "Positrack 60-90 HP \/ Truck & Trailer",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "724DC341-B5FE-4BB7-84A3-E73F72157069",
        "Name": "Positrack Penalty Rate",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F75195BD-1345-4A4F-B718-C740DEF15A44",
        "Name": "Profiler",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "7400A8F6-8104-43AD-AE4A-40B46CBFEA91",
        "Name": "Smudger",
        "SubcategoryId": "B8216C15-96B9-4244-BD03-3446F53894A2",
        "SubcategoryName": "Positrack",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6DC6D0E3-8DD8-4FD4-A300-B6EC15C986F0",
        "Name": "613 Scraper",
        "SubcategoryId": "57941E8D-494B-415B-845E-00D16FAF2B7E",
        "SubcategoryName": "Scraper",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "8CE02C35-67E4-42B0-B548-430D74FD6A7E",
        "Name": "615 Scraper",
        "SubcategoryId": "57941E8D-494B-415B-845E-00D16FAF2B7E",
        "SubcategoryName": "Scraper",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C884BEE8-56AE-4595-B85D-D957AD5C7A2F",
        "Name": "616 Scraper",
        "SubcategoryId": "57941E8D-494B-415B-845E-00D16FAF2B7E",
        "SubcategoryName": "Scraper",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "81B79CEE-7BCB-4752-9447-108B1F7BF7A1",
        "Name": "623 Scraper",
        "SubcategoryId": "57941E8D-494B-415B-845E-00D16FAF2B7E",
        "SubcategoryName": "Scraper",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "9E98A737-D945-465F-B923-5AED007C28F6",
        "Name": "Slasher",
        "SubcategoryId": "2F073C5F-B571-4FBF-B669-A423BAB14DC8",
        "SubcategoryName": "Slasher",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6753894B-27D4-4BF3-A314-BB8E8BA56E8F",
        "Name": "Slasher (Day Rate)",
        "SubcategoryId": "2F073C5F-B571-4FBF-B669-A423BAB14DC8",
        "SubcategoryName": "Slasher",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D035214D-04E7-4D54-AF80-DCD44710BD55",
        "Name": "Street Sweeper",
        "SubcategoryId": "84CF7A6F-871A-42F9-B8FC-E3A6E6C2219E",
        "SubcategoryName": "Street Sweeper",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6A07C9BB-809D-4C99-B5DF-18DCB10D14BE",
        "Name": "931 Traxcavator",
        "SubcategoryId": "23A73D03-2C8B-4C61-B4F1-EA857ECD200F",
        "SubcategoryName": "Traxcavator",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "F01B4C4F-6D28-480D-81AD-625EDBB6D298",
        "Name": "943 Traxcavator",
        "SubcategoryId": "23A73D03-2C8B-4C61-B4F1-EA857ECD200F",
        "SubcategoryName": "Traxcavator",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "C327308A-690D-4704-964B-577BFDF0EE60",
        "Name": "953 Traxcavator",
        "SubcategoryId": "23A73D03-2C8B-4C61-B4F1-EA857ECD200F",
        "SubcategoryName": "Traxcavator",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "5C3278FB-4B3F-4566-9B69-FCC71FAD5114",
        "Name": "953 Traxcavator \/ Tandem Tipper",
        "SubcategoryId": "23A73D03-2C8B-4C61-B4F1-EA857ECD200F",
        "SubcategoryName": "Traxcavator",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "383439F9-B870-4A25-96FE-27D6BBDCFF9E",
        "Name": "955 Traxcavator",
        "SubcategoryId": "23A73D03-2C8B-4C61-B4F1-EA857ECD200F",
        "SubcategoryName": "Traxcavator",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "421BE962-4DE5-4473-AAE4-FE44387ECF13",
        "Name": "963 Traxcavator",
        "SubcategoryId": "23A73D03-2C8B-4C61-B4F1-EA857ECD200F",
        "SubcategoryName": "Traxcavator",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "EF7D3A0B-42EC-491E-AD95-59F978786750",
        "Name": "Smudger",
        "SubcategoryId": "23A73D03-2C8B-4C61-B4F1-EA857ECD200F",
        "SubcategoryName": "Traxcavator",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D4CA8216-BDA9-4D4C-A326-66CCFE379751",
        "Name": "Traxcavator Penalty Rate",
        "SubcategoryId": "23A73D03-2C8B-4C61-B4F1-EA857ECD200F",
        "SubcategoryName": "Traxcavator",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "A872534E-6DE1-46B9-B173-55AE64920660",
        "Name": "Semi Tanker Water Cart",
        "SubcategoryId": "1F1A7383-C757-4168-9A9C-C99646684E81",
        "SubcategoryName": "Water Cart",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "E53AD42D-2E6B-443A-8DCF-238285DB8615",
        "Name": "Water Cart < 8000 litres- Single Axle Truck",
        "SubcategoryId": "1F1A7383-C757-4168-9A9C-C99646684E81",
        "SubcategoryName": "Water Cart",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "D58E7E75-962E-4638-A2E9-E4D244157502",
        "Name": "Water Cart > 8000 litres - Tandem",
        "SubcategoryId": "1F1A7383-C757-4168-9A9C-C99646684E81",
        "SubcategoryName": "Water Cart",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "6C92942E-A522-47F3-9308-604BB85BD1A6",
        "Name": "Water per load",
        "SubcategoryId": "1F1A7383-C757-4168-9A9C-C99646684E81",
        "SubcategoryName": "Water Cart",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "1B3BFBA3-C1CD-41CE-8C6B-1E3D2583DAA6",
        "Name": "Watercart Penalty Rate",
        "SubcategoryId": "1F1A7383-C757-4168-9A9C-C99646684E81",
        "SubcategoryName": "Water Cart",
        "CategoryId": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "CategoryName": "Plant"
    },
    {
        "Id": "90B2D605-7C20-44FB-93EE-EE5656D6CB4E",
        "Name": "Semi Tipper (EPA)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "C06C69B5-0BCD-4D43-A7EF-EAFD7C53B060",
        "Name": "Semi Tipper (Fill)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "C75BCA1B-FC07-4AF2-A053-02B19A1B6CE8",
        "Name": "Semi Tipper (Rock)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "E00665AE-1A53-4317-A525-93A501584F47",
        "Name": "Side Tipper (EPA)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "A7F9348B-1072-4B13-8B91-1FE919D3785D",
        "Name": "Side Tipper (Fill)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "5BF70D8F-47E3-42D7-A845-8AEE53342376",
        "Name": "Side Tipper (Rock)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "2724A438-8C34-433C-9490-33CC6B4B8CA6",
        "Name": "Tandem (EPA)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "D2B25AE4-D88A-411E-A94F-86CC1E8974A8",
        "Name": "Tandem (Fill)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "48FA42FB-E683-477F-B1D2-3E3981D5A19B",
        "Name": "Tandem (Rock)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "F6233923-5F60-48AC-A1E2-DEAC7BA9299A",
        "Name": "Truck & Trailer (EPA)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "8FF617D3-1C10-4BB9-8EF0-2FD5BD3696DE",
        "Name": "Truck & Trailer (Fill)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "79F6B14E-5E7D-421E-A061-C268B9162484",
        "Name": "Truck & Trailer (Rock)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "24076588-3292-43B3-A4FE-8EB5B140AF90",
        "Name": "Truck & Trailer Quad Dog (EPA)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "F6DB394F-3C52-4A21-9F66-EB7279D286E1",
        "Name": "Truck & Trailer Quad Dog (Fill)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "A854B569-D0B5-4D10-B56C-72CA20AC1A50",
        "Name": "Truck & Trailer Quad Dog (Rock)",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "248B912E-5652-4484-8454-FBE38E1A3E6A",
        "Name": "Truck & Trailer Quin Dog (EPA) ",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "6506B4CC-FBDF-4674-96CD-5353F16C89A9",
        "Name": "Truck & Trailer Quin Dog (Fill) ",
        "SubcategoryId": "64504CD8-49C5-4ACB-B990-EA42EEDA7FF5",
        "SubcategoryName": "Contract Cartage",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "8FE28A42-708F-4009-A178-079A68419D33",
        "Name": "Semi Tipper",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "3F15EA01-739E-4D8D-9532-71387F1BEA16",
        "Name": "Semi Tipper (EPA)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "994FBC8C-430F-47FE-AECD-3ACF4563F2B6",
        "Name": "Side Tipper (EPA)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "B7E8E083-8F09-4D10-A72D-3E432836D884",
        "Name": "Side Tipper (Fill)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "9AEB2FB5-854D-44AE-A19F-E6C099151D03",
        "Name": "Side Tipper (Rock)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "33A14AD1-383A-45AE-90B9-82C94CA88CBC",
        "Name": "Single Axle Tipper",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "D180CFB1-B404-41DA-B4FF-C177CA6A4307",
        "Name": "Tandem (EPA VicGov) ",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "404F3920-7534-4539-B2E9-785542053D93",
        "Name": "Tandem (EPA)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "34F8C0F7-8F60-471E-946A-9F3CA70139E8",
        "Name": "Tandem (Fill)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "EE10107C-4C04-4C10-999B-6D74E0658CF3",
        "Name": "Tandem (Rock)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "F68B98F1-5954-4CFA-82D0-F4E418889AF2",
        "Name": "Tandem (VicGov) ",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "4FA52689-90E7-4828-B483-A1570B8E53FD",
        "Name": "Truck & Trailer  (EPA)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "731FEE3C-DB6E-4EA4-A96E-61CCD80E9240",
        "Name": "Truck & Trailer  (Rock)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "465B0DFA-2B67-4800-92DE-E480014254B4",
        "Name": "Truck & Trailer (EPA VicGov) ",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "87B10ACE-C57A-42A6-8F57-505FA3D08910",
        "Name": "Truck & Trailer (Fill)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "1C9CBA20-A13A-4E19-B510-6D33F123977E",
        "Name": "Truck & Trailer (VicGov)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "7B7E80ED-A106-4E02-9E4F-FC9582AFF078",
        "Name": "Truck & Trailer Quad Dog (EPA VicGov) ",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "470B6218-ACCC-41C3-AEF6-E1ABA1C2E2DF",
        "Name": "Truck & Trailer Quad Dog (EPA)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "4212FB41-79D5-494B-B4EF-D133E05B2213",
        "Name": "Truck & Trailer Quad Dog (Fill)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {

        "Id": "1D66E05E-6E47-4B9D-8AAD-7AC235AC21AC",
        "Name": "Truck & Trailer Quad Dog (Rock)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "43155795-4372-4DD8-99CB-E1BC6071F698",
        "Name": "Truck & Trailer Quad Dog (VicGov)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "9989B62A-B422-46BA-9EE8-7C1E124B8690",
        "Name": "Truck & Trailer Quin Dog (EPA)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "189DA11C-39FB-40FE-AF3C-111A90E9AFB2",
        "Name": "Truck & Trailer Quin Dog (Fill)",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    },
    {
        "Id": "7DC979FD-5FFD-4770-86A4-3796FCD34391",
        "Name": "Truck Penalty Rate",
        "SubcategoryId": "C9EBA487-AAD7-4799-B266-C0605E87A274",
        "SubcategoryName": "Truck Hourly",
        "CategoryId": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "CategoryName": "Truck"
    }
]

export function RetrieveEquipmentTypes(categoryId: string): Promise<IEquipmentType[]> {
    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => equipmentTypes.filter(x => x.CategoryId === categoryId));
}
