import { Route, Routes } from "react-router-dom"
import AuthorizationRoute from "../auth/authorization.route"
import ProfilePage from "../common/pages/Authentication/Profile/Profile.page"
import UnauthorizedPage from "../common/pages/Authentication/Unauthorized/Unauthorized.page"
import UnreachablePage from "../common/pages/Authentication/Unreachable/Unreachable.page"
import SupportPage from "../common/pages/Support/Support.page"
import SubcontractorDocumentPage from "../pages/SubcontractorDocument/SubcontractorDocument.page"
import EquipmentPage from "../pages/Equipment/Equipment.page"
import OperatorPage from "../pages/Operator/Operator.page"
import SubcontractorQuestionaryPage from "../pages/SubcontractorQuestionary/SubcontractorQuestionary.page"
import SubcontractorRegionPage from "../pages/SubcontractorRegion/SubcontractorRegion.page"
import OperatorDocumentPage from "../pages/OperatorDocument/OperatorDocument.page"
import OperatorQuestionaryPage from "../pages/OperatorQuestionary/OperatorQuestionary.page"

export default () => {
    return <Routes>
        <Route path='' element={<OperatorDocumentPage />} />
        <Route path='home' element={<OperatorDocumentPage />} />

        <Route path='company/operator' element={<AuthorizationRoute permission='manage:operator' element={OperatorPage} />} />
        <Route path='company/equipment' element={<AuthorizationRoute permission='manage:equipment' element={EquipmentPage} />} />
        <Route path='company/region' element={<AuthorizationRoute permission='manage:region' element={SubcontractorRegionPage} />} />
        <Route path='company/document' element={<AuthorizationRoute permission='manage:subcontractor' element={SubcontractorDocumentPage} />} />
        <Route path='company/questionary' element={<AuthorizationRoute permission='manage:subcontractor' element={SubcontractorQuestionaryPage} />} />
        <Route path='operator/document' element={<OperatorDocumentPage />} />
        <Route path='operator/questionary' element={<OperatorQuestionaryPage />} />

        <Route path='support' element={<SupportPage />} />,
        <Route path='profile' element={<ProfilePage />} />,
        <Route path='unauthorized' element={<UnauthorizedPage />} />
        <Route path='*' element={<UnreachablePage />} />
    </Routes>
}