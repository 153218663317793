import { Alert } from "antd"
import Marquee from "react-fast-marquee"

export default () => {

    const marqueeComponent = <Marquee pauseOnHover gradient={false} >
        This application is a prototype and it is still in the development stage. 
        Data will not be saved.
    </Marquee>

    return <Alert banner message={marqueeComponent} />
}