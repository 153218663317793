import { v4 as uuid } from "uuid";
import { IEquipment } from '../types/IEquipment.interface';
import { categories } from "./Category.service";
import { equipmentTypes } from "./EquipmentType.service";

const collection: IEquipment[] = [
    {
        Id: uuid(),
        CategoryId: categories[0].Id,
        CategoryName: categories[0].Name,
        EquipmentTypes: equipmentTypes.filter(o => o.CategoryId === categories[0].Id).slice(0, 4),
        RegistrationNumber: "0000001"
    },
    {
        Id: uuid(),
        CategoryId: categories[0].Id,
        CategoryName: categories[0].Name,
        EquipmentTypes: equipmentTypes.filter(o => o.CategoryId === categories[0].Id).slice(0, 4),
        RegistrationNumber: "0000002"
    },
    {
        Id: uuid(),
        CategoryId: categories[0].Id,
        CategoryName: categories[0].Name,
        EquipmentTypes: equipmentTypes.filter(o => o.CategoryId === categories[0].Id).slice(0, 4),
        RegistrationNumber: "0000003"
    },
    {
        Id: uuid(),
        CategoryId: categories[0].Id,
        CategoryName: categories[0].Name,
        EquipmentTypes: equipmentTypes.filter(o => o.CategoryId === categories[0].Id).slice(0, 4),
        RegistrationNumber: "0000004"
    }
]

export function RetrieveEquipments(): Promise<IEquipment[]> {
    return new Promise(resolve => setTimeout(resolve, 300))
        .then(() => collection);
}