import { SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";
import styles from './OperatorFilter.module.scss';

interface IPropsEvents {
    refresh: () => void;
}

interface IProps extends IPropsEvents {
    children?: any;
    searching: boolean;
}

export default (props: IProps) => {
    return <Row justify="end">
        <Col >
            <Space size="small">
                {props.children}
                <Button type="primary" loading={props.searching} onClick={props.refresh} icon={<SyncOutlined />} >
                    {props.searching ? 'Loading' : 'Refresh'}
                </Button>
            </Space>
        </Col>
    </Row>
}