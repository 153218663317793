import { Icon } from "@fluentui/react";
import { Button, Dropdown, Menu, Space } from "antd";
import { IEquipmentRequest } from "../../../types/IEquipmentRequest.interface";

interface IPropsEvents {
    refresh: () => void;
}

interface IProps extends IPropsEvents {
    request: IEquipmentRequest;
}

export default (props: IProps) => {

    const menuComponent = <Menu>
        <Menu.Item onClick={props.refresh}>
            <Space>
                <Icon iconName="Edit" />
                Update
            </Space>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={props.refresh}>
            <Space>
                <Icon iconName="RemoveFromTrash" />
                Remove
            </Space>
        </Menu.Item>
    </Menu>

    return <Dropdown overlay={menuComponent} trigger={['click']}>
        <Button type="link">
            <Icon iconName="More" />
        </Button>
    </Dropdown>
}