import { Divider } from "antd";
import { Content } from "antd/lib/layout/layout";
import RegionTable from "../../components/Region/RegionTable/RegionTable.component";
import SubcontractorRegionFilter from "../../components/SubcontractorRegion/SubcontractorRegionFilter/SubcontractorRegionFilter.component";
import SubcontractorRegionUpdateButton from "../../components/SubcontractorRegion/SubcontractorRegionUpdateButton/SubcontractorRegionUpdateButton.component";
import RegionContext from "../../contexts/Region.context";
import SubcontractorRegionContext from "../../contexts/SubcontractorRegion.context";
import RegionProvider from "../../providers/Region.provider";
import SubcontractorRegionProvider from "../../providers/SubcontractorRegion.provider";

export default () => {
    return <RegionProvider>
        <SubcontractorRegionProvider>
            <RegionContext.Consumer>
                {
                    (context) => <SubcontractorRegionContext.Consumer>
                        {
                            (subcontractorContext) => {

                                return <Content>
                                    <SubcontractorRegionFilter searching={context.searching || subcontractorContext.searching}
                                        refresh={() => { context.refresh(); subcontractorContext.refresh() }} >
                                        <SubcontractorRegionUpdateButton
                                            searching={context.searching || subcontractorContext.searching}
                                            updating={subcontractorContext.updating}
                                            update={subcontractorContext.update} />
                                    </SubcontractorRegionFilter>
                                    <Divider />
                                    <RegionTable
                                        searching={subcontractorContext.searching}
                                        regions={context.regions} selection={subcontractorContext.subcontractorRegionsSelection}
                                        select={subcontractorContext.select} />
                                </Content>
                            }
                        }
                    </SubcontractorRegionContext.Consumer>
                }
            </RegionContext.Consumer>
        </SubcontractorRegionProvider >
    </RegionProvider>
}