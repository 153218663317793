import { LoadingOutlined } from '@ant-design/icons';
import { Icon } from '@fluentui/react';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { properties } from '../../../common/properties/Spin.properties';
import { IEquipment } from '../../../types/IEquipment.interface';
import EquipmentRequestActionButton from '../../EquipmentRequest/EquipmentRequestActionButton/EquipmentRequestActionButton.component';
import EquipmentTypePopup from '../../EquipmentType/EquipmentTypePopup/EquipmentTypePopup.component';
import EquipmentActionButton from '../EquipmentActionButton/EquipmentActionButton.component';
import styles from './EquipmentTable.module.scss';

interface IPropsEvents {
    refresh: () => void;
}

interface IProps extends IPropsEvents {
    searching: boolean;
    equipments: IEquipment[];
}

export default (props: IProps) => {

    const columns: ColumnsType<IEquipment> = [
        {
            title: 'Category Name',
            dataIndex: 'CategoryName'
        },
        {
            title: 'Equipment Types',
            render: record => <EquipmentTypePopup items={record.EquipmentTypes} />
        },
        {
            title: 'Registration Number',
            dataIndex: 'RegistrationNumber'
        },
        {
            title: 'Status',
            render: record => <Space size='small'>
                {
                    record.Request
                        ? <LoadingOutlined />
                        : <Icon iconName="Completed" className={styles.icon} style={{ color: 'green' }} />
                }
                {
                    record.Request
                        ? 'Awaiting Approval'
                        : 'Active'
                }
            </Space>
        },
        {
            align: 'right',
            render: record => record.Request
                ? <EquipmentRequestActionButton request={record} refresh={props.refresh} />
                : <EquipmentActionButton equipment={record} refresh={props.refresh} />
        }
    ];

    return <Table
        size="small"
        loading={{ ...properties, spinning: props.searching }}
        rowKey={o => o.Id}
        columns={columns}
        dataSource={props.equipments}
    />
}
