import { LoadingOutlined } from "@ant-design/icons";
import { Icon } from "@fluentui/react";
import { Space } from "antd";
import { IQuestionary } from "../../../types/IQuestionary.interface";
import styles from "./QuestionaryStatusIcon.module.scss";

interface IProps {
    questionary: IQuestionary;
}

export default (props: IProps) => {

    if (!props.questionary)
        return <Space size='small'>
            <Icon iconName="Unknown" className={styles.icon} />
            Unknown
        </Space>

    else if (props.questionary.Pending)
        return <Space size='small'>
            <Icon iconName="Clock" className={styles.icon} />
            Pending 
        </Space>

    else if (props.questionary.Submitted)
        return <Space size='small'>
            <LoadingOutlined spin />
            Processing
        </Space>

    else if (props.questionary.Expiring)
        return <Space size='small'>
            <Icon iconName="Error" className={styles.icon} style={{ color: 'orange' }} />
            Expiring
        </Space>

    else if (props.questionary.Expired)
        return <Space size='small'>
            <Icon iconName="ErrorBadge" className={styles.icon} style={{ color: 'red' }} />
            Expired
        </Space>

    else if (props.questionary.Completed) {
        if (props.questionary.Approved)
            return <Space size='small'>
                <Icon iconName="Like" className={styles.icon} />
                Approved
            </Space>

        else if (props.questionary.Failed)
            return <Space size='small'>
                <Icon iconName="Dislike" className={styles.icon} />
                Failed
            </Space>
    }

    return <Space size='small'>
        <Icon iconName="Unknown" className={styles.icon} />
        Unknown
    </Space>
}