import { ICategory } from '../types/ICategory.interface';

export const categories: ICategory[] = 
[
    {
        "Id": "6FB3EA37-AE21-45AC-95E9-4C42D3644E80",
        "Name": "Plant"
    },
    {
        "Id": "BD3BAA3D-E8E0-419B-86E0-24D3F916F518",
        "Name": "Truck"
    }
]

export function RetrieveCategories(): Promise<ICategory[]> {
    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => categories);
}