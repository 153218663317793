import { IUserAgreement } from "../types/IUserAgreement.interface";
import { v4 as uuid } from 'uuid'

const agreements = [
    {
        Id: uuid(),
        Name: "Terms of Service",
        Content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suscipit tellus mauris a diam maecenas. Augue ut lectus arcu bibendum. Ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Sed viverra tellus in hac habitasse platea. Ut eu sem integer vitae. Tincidunt dui ut ornare lectus sit amet est. Et pharetra pharetra massa massa ultricies mi. Euismod lacinia at quis risus sed. Ornare aenean euismod elementum nisi quis eleifend quam adipiscing vitae.
        Tortor id aliquet lectus proin nibh nisl condimentum id. Volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Nec feugiat nisl pretium fusce id. Magna etiam tempor orci eu. Cras sed felis eget velit aliquet sagittis id consectetur. Non arcu risus quis varius quam quisque. Arcu risus quis varius quam. Amet facilisis magna etiam tempor orci eu lobortis. Nibh tortor id aliquet lectus proin nibh. Eget duis at tellus at. Netus et malesuada fames ac turpis egestas maecenas pharetra. Odio eu feugiat pretium nibh. Lectus urna duis convallis convallis tellus id interdum velit. Sit amet nisl purus in mollis nunc. Ultrices in iaculis nunc sed augue lacus. Sem integer vitae justo eget. Blandit cursus risus at ultrices mi. Sagittis nisl rhoncus mattis rhoncus urna neque. Hendrerit dolor magna eget est lorem ipsum dolor. Tellus cras adipiscing enim eu turpis egestas pretium aenean pharetra.
        Ipsum suspendisse ultrices gravida dictum fusce ut. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Placerat orci nulla pellentesque dignissim enim sit amet. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing. Fames ac turpis egestas integer eget. Tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas ultricies mi eget mauris pharetra et ultrices. In nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Non blandit massa enim nec dui nunc mattis enim. Eget mi proin sed libero enim sed. Mauris pharetra et ultrices neque ornare aenean. Tellus elementum sagittis vitae et leo duis. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan.
        Massa tempor nec feugiat nisl. Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant. Amet risus nullam eget felis eget nunc lobortis mattis. Tortor dignissim convallis aenean et tortor at. Quis hendrerit dolor magna eget est lorem ipsum dolor. Vel turpis nunc eget lorem dolor sed viverra. Convallis tellus id interdum velit laoreet id. Curabitur vitae nunc sed velit dignissim. Nibh ipsum consequat nisl vel pretium lectus. Dolor sit amet consectetur adipiscing. Est ultricies integer quis auctor elit sed vulputate. Sit amet justo donec enim diam vulputate ut pharetra. Vel facilisis volutpat est velit egestas dui. Consequat interdum varius sit amet mattis vulputate enim nulla aliquet. Laoreet non curabitur gravida arcu ac tortor.
        Integer malesuada nunc vel risus commodo. Maecenas sed enim ut sem viverra aliquet eget sit amet. Nisl purus in mollis nunc sed. Feugiat pretium nibh ipsum consequat nisl vel pretium. Non nisi est sit amet facilisis magna etiam. Volutpat sed cras ornare arcu. Auctor neque vitae tempus quam pellentesque nec. Urna id volutpat lacus laoreet non curabitur gravida. Scelerisque felis imperdiet proin fermentum leo vel orci porta non. Dui faucibus in ornare quam viverra orci sagittis.
        Ipsum dolor sit amet consectetur adipiscing elit duis. Sapien et ligula ullamcorper malesuada proin libero nunc. Faucibus a pellentesque sit amet porttitor eget dolor morbi. Rhoncus mattis rhoncus urna neque viverra. Vitae elementum curabitur vitae nunc sed velit. Sit amet aliquam id diam maecenas. Amet nisl suscipit adipiscing bibendum est ultricies integer quis. Odio tempor orci dapibus ultrices in. Dignissim enim sit amet venenatis urna cursus eget nunc. Ut tortor pretium viverra suspendisse potenti nullam ac tortor. Ipsum dolor sit amet consectetur adipiscing elit. Eget arcu dictum varius duis at consectetur. Tincidunt praesent semper feugiat nibh.
        Velit scelerisque in dictum non consectetur a erat nam at. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo. Vitae elementum curabitur vitae nunc sed velit dignissim sodales. Purus viverra accumsan in nisl nisi scelerisque eu. Eget aliquet nibh praesent tristique magna sit amet purus gravida. Aenean sed adipiscing diam donec adipiscing tristique. Sit amet nisl suscipit adipiscing bibendum est. Ut placerat orci nulla pellentesque dignissim enim sit amet. Leo integer malesuada nunc vel risus commodo viverra maecenas. Scelerisque viverra mauris in aliquam sem fringilla ut morbi tincidunt. Purus gravida quis blandit turpis cursus in. Sem fringilla ut morbi tincidunt augue interdum velit euismod. Turpis massa tincidunt dui ut ornare. Adipiscing commodo elit at imperdiet dui accumsan sit amet nulla. Auctor neque vitae tempus quam pellentesque nec nam aliquam. Iaculis nunc sed augue lacus viverra vitae congue. Porttitor lacus luctus accumsan tortor posuere ac ut consequat semper. Eu turpis egestas pretium aenean.
        Tortor posuere ac ut consequat. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Cursus eget nunc scelerisque viverra mauris in aliquam. Faucibus pulvinar elementum integer enim neque volutpat. In iaculis nunc sed augue lacus viverra vitae. Nunc sed blandit libero volutpat. Felis eget velit aliquet sagittis id consectetur purus. Sit amet facilisis magna etiam tempor orci eu lobortis. At consectetur lorem donec massa sapien. Turpis tincidunt id aliquet risus feugiat in ante. Quam elementum pulvinar etiam non quam lacus suspendisse faucibus interdum.
        Congue mauris rhoncus aenean vel. Urna cursus eget nunc scelerisque. Ut enim blandit volutpat maecenas volutpat blandit. Nec ullamcorper sit amet risus nullam. Commodo elit at imperdiet dui. Ipsum dolor sit amet consectetur. Duis convallis convallis tellus id interdum. Viverra ipsum nunc aliquet bibendum enim facilisis gravida neque convallis. Pretium aenean pharetra magna ac placerat vestibulum. Bibendum est ultricies integer quis auctor elit sed vulputate. Libero justo laoreet sit amet cursus sit amet. Metus vulputate eu scelerisque felis.
        Turpis massa sed elementum tempus. Tellus rutrum tellus pellentesque eu tincidunt tortor. Augue ut lectus arcu bibendum at varius vel pharetra vel. Luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. Feugiat in fermentum posuere urna nec tincidunt. Adipiscing tristique risus nec feugiat in. Iaculis eu non diam phasellus vestibulum lorem sed risus. Aliquet nec ullamcorper sit amet risus nullam eget felis. Nulla facilisi nullam vehicula ipsum. Suscipit adipiscing bibendum est ultricies integer. Ipsum faucibus vitae aliquet nec ullamcorper sit amet risus. Mi bibendum neque egestas congue quisque egestas diam in. Viverra mauris in aliquam sem fringilla ut morbi. Semper eget duis at tellus at urna condimentum. Neque convallis a cras semper auctor. Justo nec ultrices dui sapien eget mi proin sed libero. Lacinia at quis risus sed. Interdum velit euismod in pellentesque massa. Feugiat vivamus at augue eget. Cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo.
        Volutpat ac tincidunt vitae semper quis. Aenean euismod elementum nisi quis eleifend quam adipiscing vitae. Diam ut venenatis tellus in metus vulputate eu. Nulla malesuada pellentesque elit eget. Ut aliquam purus sit amet. Odio tempor orci dapibus ultrices. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. At urna condimentum mattis pellentesque. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum. Natoque penatibus et magnis dis parturient montes. Sapien eget mi proin sed libero enim sed faucibus turpis.
        Integer enim neque volutpat ac tincidunt. Pharetra diam sit amet nisl suscipit. Velit dignissim sodales ut eu sem integer vitae justo eget. Eget est lorem ipsum dolor sit amet consectetur adipiscing elit. Justo donec enim diam vulputate ut pharetra sit. Eu non diam phasellus vestibulum. Amet purus gravida quis blandit. Rhoncus urna neque viverra justo nec ultrices dui sapien eget. Bibendum ut tristique et egestas quis ipsum. Cursus vitae congue mauris rhoncus aenean. Elit pellentesque habitant morbi tristique senectus et netus et malesuada. Et egestas quis ipsum suspendisse ultrices gravida dictum. In hac habitasse platea dictumst vestibulum rhoncus est pellentesque.
        Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula ipsum. Dignissim suspendisse in est ante. Porttitor lacus luctus accumsan tortor. Felis imperdiet proin fermentum leo vel. Urna id volutpat lacus laoreet. A arcu cursus vitae congue mauris rhoncus aenean vel. Malesuada nunc vel risus commodo viverra. Pellentesque nec nam aliquam sem et tortor consequat id porta. Id porta nibh venenatis cras sed felis. Donec ultrices tincidunt arcu non sodales neque sodales.
        Cum sociis natoque penatibus et magnis dis. Et netus et malesuada fames ac turpis egestas integer. Interdum velit euismod in pellentesque. Velit sed ullamcorper morbi tincidunt ornare. Habitant morbi tristique senectus et netus et malesuada fames ac. Nunc consequat interdum varius sit amet. Vulputate dignissim suspendisse in est ante in nibh mauris. Mauris augue neque gravida in fermentum et sollicitudin ac orci. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Ut placerat orci nulla pellentesque dignissim enim sit. Diam sit amet nisl suscipit adipiscing. Et sollicitudin ac orci phasellus egestas tellus rutrum tellus. Felis bibendum ut tristique et egestas quis. Commodo elit at imperdiet dui accumsan sit amet. Non tellus orci ac auctor augue mauris augue. Consequat interdum varius sit amet mattis vulputate enim nulla. Diam vel quam elementum pulvinar.`
    },
    {
        Id: uuid(),
        Name: "Privacy Policy",
        Content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suscipit tellus mauris a diam maecenas. Augue ut lectus arcu bibendum. Ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Sed viverra tellus in hac habitasse platea. Ut eu sem integer vitae. Tincidunt dui ut ornare lectus sit amet est. Et pharetra pharetra massa massa ultricies mi. Euismod lacinia at quis risus sed. Ornare aenean euismod elementum nisi quis eleifend quam adipiscing vitae.
        Tortor id aliquet lectus proin nibh nisl condimentum id. Volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Nec feugiat nisl pretium fusce id. Magna etiam tempor orci eu. Cras sed felis eget velit aliquet sagittis id consectetur. Non arcu risus quis varius quam quisque. Arcu risus quis varius quam. Amet facilisis magna etiam tempor orci eu lobortis. Nibh tortor id aliquet lectus proin nibh. Eget duis at tellus at. Netus et malesuada fames ac turpis egestas maecenas pharetra. Odio eu feugiat pretium nibh. Lectus urna duis convallis convallis tellus id interdum velit. Sit amet nisl purus in mollis nunc. Ultrices in iaculis nunc sed augue lacus. Sem integer vitae justo eget. Blandit cursus risus at ultrices mi. Sagittis nisl rhoncus mattis rhoncus urna neque. Hendrerit dolor magna eget est lorem ipsum dolor. Tellus cras adipiscing enim eu turpis egestas pretium aenean pharetra.
        Ipsum suspendisse ultrices gravida dictum fusce ut. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Placerat orci nulla pellentesque dignissim enim sit amet. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing. Fames ac turpis egestas integer eget. Tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas ultricies mi eget mauris pharetra et ultrices. In nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Non blandit massa enim nec dui nunc mattis enim. Eget mi proin sed libero enim sed. Mauris pharetra et ultrices neque ornare aenean. Tellus elementum sagittis vitae et leo duis. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan.
        Massa tempor nec feugiat nisl. Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant. Amet risus nullam eget felis eget nunc lobortis mattis. Tortor dignissim convallis aenean et tortor at. Quis hendrerit dolor magna eget est lorem ipsum dolor. Vel turpis nunc eget lorem dolor sed viverra. Convallis tellus id interdum velit laoreet id. Curabitur vitae nunc sed velit dignissim. Nibh ipsum consequat nisl vel pretium lectus. Dolor sit amet consectetur adipiscing. Est ultricies integer quis auctor elit sed vulputate. Sit amet justo donec enim diam vulputate ut pharetra. Vel facilisis volutpat est velit egestas dui. Consequat interdum varius sit amet mattis vulputate enim nulla aliquet. Laoreet non curabitur gravida arcu ac tortor.
        Integer malesuada nunc vel risus commodo. Maecenas sed enim ut sem viverra aliquet eget sit amet. Nisl purus in mollis nunc sed. Feugiat pretium nibh ipsum consequat nisl vel pretium. Non nisi est sit amet facilisis magna etiam. Volutpat sed cras ornare arcu. Auctor neque vitae tempus quam pellentesque nec. Urna id volutpat lacus laoreet non curabitur gravida. Scelerisque felis imperdiet proin fermentum leo vel orci porta non. Dui faucibus in ornare quam viverra orci sagittis.
        Ipsum dolor sit amet consectetur adipiscing elit duis. Sapien et ligula ullamcorper malesuada proin libero nunc. Faucibus a pellentesque sit amet porttitor eget dolor morbi. Rhoncus mattis rhoncus urna neque viverra. Vitae elementum curabitur vitae nunc sed velit. Sit amet aliquam id diam maecenas. Amet nisl suscipit adipiscing bibendum est ultricies integer quis. Odio tempor orci dapibus ultrices in. Dignissim enim sit amet venenatis urna cursus eget nunc. Ut tortor pretium viverra suspendisse potenti nullam ac tortor. Ipsum dolor sit amet consectetur adipiscing elit. Eget arcu dictum varius duis at consectetur. Tincidunt praesent semper feugiat nibh.
        Velit scelerisque in dictum non consectetur a erat nam at. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo. Vitae elementum curabitur vitae nunc sed velit dignissim sodales. Purus viverra accumsan in nisl nisi scelerisque eu. Eget aliquet nibh praesent tristique magna sit amet purus gravida. Aenean sed adipiscing diam donec adipiscing tristique. Sit amet nisl suscipit adipiscing bibendum est. Ut placerat orci nulla pellentesque dignissim enim sit amet. Leo integer malesuada nunc vel risus commodo viverra maecenas. Scelerisque viverra mauris in aliquam sem fringilla ut morbi tincidunt. Purus gravida quis blandit turpis cursus in. Sem fringilla ut morbi tincidunt augue interdum velit euismod. Turpis massa tincidunt dui ut ornare. Adipiscing commodo elit at imperdiet dui accumsan sit amet nulla. Auctor neque vitae tempus quam pellentesque nec nam aliquam. Iaculis nunc sed augue lacus viverra vitae congue. Porttitor lacus luctus accumsan tortor posuere ac ut consequat semper. Eu turpis egestas pretium aenean.
        Tortor posuere ac ut consequat. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Cursus eget nunc scelerisque viverra mauris in aliquam. Faucibus pulvinar elementum integer enim neque volutpat. In iaculis nunc sed augue lacus viverra vitae. Nunc sed blandit libero volutpat. Felis eget velit aliquet sagittis id consectetur purus. Sit amet facilisis magna etiam tempor orci eu lobortis. At consectetur lorem donec massa sapien. Turpis tincidunt id aliquet risus feugiat in ante. Quam elementum pulvinar etiam non quam lacus suspendisse faucibus interdum.
        Congue mauris rhoncus aenean vel. Urna cursus eget nunc scelerisque. Ut enim blandit volutpat maecenas volutpat blandit. Nec ullamcorper sit amet risus nullam. Commodo elit at imperdiet dui. Ipsum dolor sit amet consectetur. Duis convallis convallis tellus id interdum. Viverra ipsum nunc aliquet bibendum enim facilisis gravida neque convallis. Pretium aenean pharetra magna ac placerat vestibulum. Bibendum est ultricies integer quis auctor elit sed vulputate. Libero justo laoreet sit amet cursus sit amet. Metus vulputate eu scelerisque felis.
        Turpis massa sed elementum tempus. Tellus rutrum tellus pellentesque eu tincidunt tortor. Augue ut lectus arcu bibendum at varius vel pharetra vel. Luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. Feugiat in fermentum posuere urna nec tincidunt. Adipiscing tristique risus nec feugiat in. Iaculis eu non diam phasellus vestibulum lorem sed risus. Aliquet nec ullamcorper sit amet risus nullam eget felis. Nulla facilisi nullam vehicula ipsum. Suscipit adipiscing bibendum est ultricies integer. Ipsum faucibus vitae aliquet nec ullamcorper sit amet risus. Mi bibendum neque egestas congue quisque egestas diam in. Viverra mauris in aliquam sem fringilla ut morbi. Semper eget duis at tellus at urna condimentum. Neque convallis a cras semper auctor. Justo nec ultrices dui sapien eget mi proin sed libero. Lacinia at quis risus sed. Interdum velit euismod in pellentesque massa. Feugiat vivamus at augue eget. Cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo.
        Volutpat ac tincidunt vitae semper quis. Aenean euismod elementum nisi quis eleifend quam adipiscing vitae. Diam ut venenatis tellus in metus vulputate eu. Nulla malesuada pellentesque elit eget. Ut aliquam purus sit amet. Odio tempor orci dapibus ultrices. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. At urna condimentum mattis pellentesque. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum. Natoque penatibus et magnis dis parturient montes. Sapien eget mi proin sed libero enim sed faucibus turpis.
        Integer enim neque volutpat ac tincidunt. Pharetra diam sit amet nisl suscipit. Velit dignissim sodales ut eu sem integer vitae justo eget. Eget est lorem ipsum dolor sit amet consectetur adipiscing elit. Justo donec enim diam vulputate ut pharetra sit. Eu non diam phasellus vestibulum. Amet purus gravida quis blandit. Rhoncus urna neque viverra justo nec ultrices dui sapien eget. Bibendum ut tristique et egestas quis ipsum. Cursus vitae congue mauris rhoncus aenean. Elit pellentesque habitant morbi tristique senectus et netus et malesuada. Et egestas quis ipsum suspendisse ultrices gravida dictum. In hac habitasse platea dictumst vestibulum rhoncus est pellentesque.
        Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula ipsum. Dignissim suspendisse in est ante. Porttitor lacus luctus accumsan tortor. Felis imperdiet proin fermentum leo vel. Urna id volutpat lacus laoreet. A arcu cursus vitae congue mauris rhoncus aenean vel. Malesuada nunc vel risus commodo viverra. Pellentesque nec nam aliquam sem et tortor consequat id porta. Id porta nibh venenatis cras sed felis. Donec ultrices tincidunt arcu non sodales neque sodales.
        Cum sociis natoque penatibus et magnis dis. Et netus et malesuada fames ac turpis egestas integer. Interdum velit euismod in pellentesque. Velit sed ullamcorper morbi tincidunt ornare. Habitant morbi tristique senectus et netus et malesuada fames ac. Nunc consequat interdum varius sit amet. Vulputate dignissim suspendisse in est ante in nibh mauris. Mauris augue neque gravida in fermentum et sollicitudin ac orci. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Ut placerat orci nulla pellentesque dignissim enim sit. Diam sit amet nisl suscipit adipiscing. Et sollicitudin ac orci phasellus egestas tellus rutrum tellus. Felis bibendum ut tristique et egestas quis. Commodo elit at imperdiet dui accumsan sit amet. Non tellus orci ac auctor augue mauris augue. Consequat interdum varius sit amet mattis vulputate enim nulla. Diam vel quam elementum pulvinar.`
    },
    {
        Id: uuid(),
        Name: "Agreement 3",
        Content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suscipit tellus mauris a diam maecenas. Augue ut lectus arcu bibendum. Ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Sed viverra tellus in hac habitasse platea. Ut eu sem integer vitae. Tincidunt dui ut ornare lectus sit amet est. Et pharetra pharetra massa massa ultricies mi. Euismod lacinia at quis risus sed. Ornare aenean euismod elementum nisi quis eleifend quam adipiscing vitae.
        Tortor id aliquet lectus proin nibh nisl condimentum id. Volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Nec feugiat nisl pretium fusce id. Magna etiam tempor orci eu. Cras sed felis eget velit aliquet sagittis id consectetur. Non arcu risus quis varius quam quisque. Arcu risus quis varius quam. Amet facilisis magna etiam tempor orci eu lobortis. Nibh tortor id aliquet lectus proin nibh. Eget duis at tellus at. Netus et malesuada fames ac turpis egestas maecenas pharetra. Odio eu feugiat pretium nibh. Lectus urna duis convallis convallis tellus id interdum velit. Sit amet nisl purus in mollis nunc. Ultrices in iaculis nunc sed augue lacus. Sem integer vitae justo eget. Blandit cursus risus at ultrices mi. Sagittis nisl rhoncus mattis rhoncus urna neque. Hendrerit dolor magna eget est lorem ipsum dolor. Tellus cras adipiscing enim eu turpis egestas pretium aenean pharetra.
        Ipsum suspendisse ultrices gravida dictum fusce ut. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Placerat orci nulla pellentesque dignissim enim sit amet. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing. Fames ac turpis egestas integer eget. Tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas ultricies mi eget mauris pharetra et ultrices. In nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Non blandit massa enim nec dui nunc mattis enim. Eget mi proin sed libero enim sed. Mauris pharetra et ultrices neque ornare aenean. Tellus elementum sagittis vitae et leo duis. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan.
        Massa tempor nec feugiat nisl. Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant. Amet risus nullam eget felis eget nunc lobortis mattis. Tortor dignissim convallis aenean et tortor at. Quis hendrerit dolor magna eget est lorem ipsum dolor. Vel turpis nunc eget lorem dolor sed viverra. Convallis tellus id interdum velit laoreet id. Curabitur vitae nunc sed velit dignissim. Nibh ipsum consequat nisl vel pretium lectus. Dolor sit amet consectetur adipiscing. Est ultricies integer quis auctor elit sed vulputate. Sit amet justo donec enim diam vulputate ut pharetra. Vel facilisis volutpat est velit egestas dui. Consequat interdum varius sit amet mattis vulputate enim nulla aliquet. Laoreet non curabitur gravida arcu ac tortor.
        Integer malesuada nunc vel risus commodo. Maecenas sed enim ut sem viverra aliquet eget sit amet. Nisl purus in mollis nunc sed. Feugiat pretium nibh ipsum consequat nisl vel pretium. Non nisi est sit amet facilisis magna etiam. Volutpat sed cras ornare arcu. Auctor neque vitae tempus quam pellentesque nec. Urna id volutpat lacus laoreet non curabitur gravida. Scelerisque felis imperdiet proin fermentum leo vel orci porta non. Dui faucibus in ornare quam viverra orci sagittis.
        Ipsum dolor sit amet consectetur adipiscing elit duis. Sapien et ligula ullamcorper malesuada proin libero nunc. Faucibus a pellentesque sit amet porttitor eget dolor morbi. Rhoncus mattis rhoncus urna neque viverra. Vitae elementum curabitur vitae nunc sed velit. Sit amet aliquam id diam maecenas. Amet nisl suscipit adipiscing bibendum est ultricies integer quis. Odio tempor orci dapibus ultrices in. Dignissim enim sit amet venenatis urna cursus eget nunc. Ut tortor pretium viverra suspendisse potenti nullam ac tortor. Ipsum dolor sit amet consectetur adipiscing elit. Eget arcu dictum varius duis at consectetur. Tincidunt praesent semper feugiat nibh.
        Velit scelerisque in dictum non consectetur a erat nam at. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo. Vitae elementum curabitur vitae nunc sed velit dignissim sodales. Purus viverra accumsan in nisl nisi scelerisque eu. Eget aliquet nibh praesent tristique magna sit amet purus gravida. Aenean sed adipiscing diam donec adipiscing tristique. Sit amet nisl suscipit adipiscing bibendum est. Ut placerat orci nulla pellentesque dignissim enim sit amet. Leo integer malesuada nunc vel risus commodo viverra maecenas. Scelerisque viverra mauris in aliquam sem fringilla ut morbi tincidunt. Purus gravida quis blandit turpis cursus in. Sem fringilla ut morbi tincidunt augue interdum velit euismod. Turpis massa tincidunt dui ut ornare. Adipiscing commodo elit at imperdiet dui accumsan sit amet nulla. Auctor neque vitae tempus quam pellentesque nec nam aliquam. Iaculis nunc sed augue lacus viverra vitae congue. Porttitor lacus luctus accumsan tortor posuere ac ut consequat semper. Eu turpis egestas pretium aenean.
        Tortor posuere ac ut consequat. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Cursus eget nunc scelerisque viverra mauris in aliquam. Faucibus pulvinar elementum integer enim neque volutpat. In iaculis nunc sed augue lacus viverra vitae. Nunc sed blandit libero volutpat. Felis eget velit aliquet sagittis id consectetur purus. Sit amet facilisis magna etiam tempor orci eu lobortis. At consectetur lorem donec massa sapien. Turpis tincidunt id aliquet risus feugiat in ante. Quam elementum pulvinar etiam non quam lacus suspendisse faucibus interdum.
        Congue mauris rhoncus aenean vel. Urna cursus eget nunc scelerisque. Ut enim blandit volutpat maecenas volutpat blandit. Nec ullamcorper sit amet risus nullam. Commodo elit at imperdiet dui. Ipsum dolor sit amet consectetur. Duis convallis convallis tellus id interdum. Viverra ipsum nunc aliquet bibendum enim facilisis gravida neque convallis. Pretium aenean pharetra magna ac placerat vestibulum. Bibendum est ultricies integer quis auctor elit sed vulputate. Libero justo laoreet sit amet cursus sit amet. Metus vulputate eu scelerisque felis.
        Turpis massa sed elementum tempus. Tellus rutrum tellus pellentesque eu tincidunt tortor. Augue ut lectus arcu bibendum at varius vel pharetra vel. Luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. Feugiat in fermentum posuere urna nec tincidunt. Adipiscing tristique risus nec feugiat in. Iaculis eu non diam phasellus vestibulum lorem sed risus. Aliquet nec ullamcorper sit amet risus nullam eget felis. Nulla facilisi nullam vehicula ipsum. Suscipit adipiscing bibendum est ultricies integer. Ipsum faucibus vitae aliquet nec ullamcorper sit amet risus. Mi bibendum neque egestas congue quisque egestas diam in. Viverra mauris in aliquam sem fringilla ut morbi. Semper eget duis at tellus at urna condimentum. Neque convallis a cras semper auctor. Justo nec ultrices dui sapien eget mi proin sed libero. Lacinia at quis risus sed. Interdum velit euismod in pellentesque massa. Feugiat vivamus at augue eget. Cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo.
        Volutpat ac tincidunt vitae semper quis. Aenean euismod elementum nisi quis eleifend quam adipiscing vitae. Diam ut venenatis tellus in metus vulputate eu. Nulla malesuada pellentesque elit eget. Ut aliquam purus sit amet. Odio tempor orci dapibus ultrices. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. At urna condimentum mattis pellentesque. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum. Natoque penatibus et magnis dis parturient montes. Sapien eget mi proin sed libero enim sed faucibus turpis.
        Integer enim neque volutpat ac tincidunt. Pharetra diam sit amet nisl suscipit. Velit dignissim sodales ut eu sem integer vitae justo eget. Eget est lorem ipsum dolor sit amet consectetur adipiscing elit. Justo donec enim diam vulputate ut pharetra sit. Eu non diam phasellus vestibulum. Amet purus gravida quis blandit. Rhoncus urna neque viverra justo nec ultrices dui sapien eget. Bibendum ut tristique et egestas quis ipsum. Cursus vitae congue mauris rhoncus aenean. Elit pellentesque habitant morbi tristique senectus et netus et malesuada. Et egestas quis ipsum suspendisse ultrices gravida dictum. In hac habitasse platea dictumst vestibulum rhoncus est pellentesque.
        Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula ipsum. Dignissim suspendisse in est ante. Porttitor lacus luctus accumsan tortor. Felis imperdiet proin fermentum leo vel. Urna id volutpat lacus laoreet. A arcu cursus vitae congue mauris rhoncus aenean vel. Malesuada nunc vel risus commodo viverra. Pellentesque nec nam aliquam sem et tortor consequat id porta. Id porta nibh venenatis cras sed felis. Donec ultrices tincidunt arcu non sodales neque sodales.
        Cum sociis natoque penatibus et magnis dis. Et netus et malesuada fames ac turpis egestas integer. Interdum velit euismod in pellentesque. Velit sed ullamcorper morbi tincidunt ornare. Habitant morbi tristique senectus et netus et malesuada fames ac. Nunc consequat interdum varius sit amet. Vulputate dignissim suspendisse in est ante in nibh mauris. Mauris augue neque gravida in fermentum et sollicitudin ac orci. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Ut placerat orci nulla pellentesque dignissim enim sit. Diam sit amet nisl suscipit adipiscing. Et sollicitudin ac orci phasellus egestas tellus rutrum tellus. Felis bibendum ut tristique et egestas quis. Commodo elit at imperdiet dui accumsan sit amet. Non tellus orci ac auctor augue mauris augue. Consequat interdum varius sit amet mattis vulputate enim nulla. Diam vel quam elementum pulvinar.`
    }
]

export function RetrieveUserAgreements(): Promise<IUserAgreement[]> {
    return new Promise(resolve => setTimeout(resolve, 2000))
        .then(() => agreements);
}

export function AcceptUserAgreement(agreementIds: string[]): Promise<boolean> {
    return new Promise(resolve => setTimeout(resolve, 1500))
        .then(() => true);
}