import { Modal } from "antd";
import { IOperatorRequest } from "../../../types/IOperatorRequest.interface";
import OperatorRequestCreateForm from "../OperatorRequestCreateForm/OperatorRequestCreateForm.component";

interface IPropsEvents {
    create: (request: IOperatorRequest) => void;
    close: () => void;
}

interface IProps extends IPropsEvents {
    visibility: boolean;
    creating: boolean;
}

export default (props: IProps) => {
    return <Modal open={props.visibility} onCancel={props.close} maskClosable={false} centered={true} closable={true} footer={null}>
        <h3>
            New Operator
        </h3>
        <OperatorRequestCreateForm create={props.create} cancel={props.close}>
        </OperatorRequestCreateForm>
    </Modal>
}