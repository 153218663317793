import { ContextualMenuItemType, Icon, IconButton, IContextualMenuProps } from "@fluentui/react"
import { Button, Dropdown, Menu, Space } from "antd";
import { IDocument } from '../../../types/IDocument.interface';

interface IPropsEvents {
    refresh: (document: IDocument) => void;
}

interface IProps extends IPropsEvents {
    document: IDocument;
}

export default (props: IProps) => {

    const download = () => {
        console.log('🚀 download', props.document);
    }

    const upload = () => {
        console.log('🚀 upload', props.document);
        props.refresh(props.document);
    }

    const activity = () => {
        console.log('🚀 activity', props.document);
    }

    const menuComponent = <Menu>
        <Menu.Item onClick={download} disabled={props?.document?.Pending}>
            <Space>
                <Icon iconName="Download" />
                Download
            </Space>
        </Menu.Item>
        <Menu.Item onClick={upload} disabled={props?.document?.AwaitingApproval || props?.document?.Compliant}>
            <Space>
                <Icon iconName="Upload" />
                Upload
            </Space>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={activity}>
            <Space>
                <Icon iconName="ActivityFeed" />
                History
            </Space>
        </Menu.Item>
    </Menu>

    return <Dropdown overlay={menuComponent} trigger={['click']}>
        <Button type="link">
            <Icon iconName="More" />
        </Button>
    </Dropdown>
}