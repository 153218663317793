import { v4 as uuid } from "uuid";
import { IOperatorRequest } from '../types/IOperatorRequest.interface';

export const operatorRequests: IOperatorRequest[] = [
    {
        Id: uuid(),
        Name: "John Doe",
        Email: "john@company.com.au",
        PhoneNumber: "0426280597",
        Request: true
    },
    {
        Id: uuid(),
        Name: "Jane Doe",
        Email: "jane@company.com.au",
        PhoneNumber: "0426280597",
        Request: true
    }
]

export function RetrieveOperatorRequests(): Promise<IOperatorRequest[]> {
    return new Promise(resolve => setTimeout(resolve, 2000))
        .then(() => [...operatorRequests]);
}


export function CreateRequest(request: IOperatorRequest): Promise<IOperatorRequest> {

    if (operatorRequests.length > 2)
        return new Promise((resolve, reject) => {
            throw new Error("Maximum number of requests reached");
        });

    operatorRequests.push(request);
    
    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => request);
}