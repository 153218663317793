import { v4 as uuid } from "uuid";
import { IEquipmentRequest } from "../types/IEquipmentRequest.interface";
import { categories } from "./Category.service";
import { equipmentTypes } from "./EquipmentType.service";

export const equipmentRequests: IEquipmentRequest[] = [
    {
        Id: uuid(),
        CategoryId: categories[0].Id,
        CategoryName: categories[0].Name,
        EquipmentTypes: equipmentTypes.filter(o => o.CategoryId === categories[0].Id).slice(0, 4),
        RegistrationNumber: "ABC12345",
        Request: true
    },
    {
        Id: uuid(),
        CategoryId: categories[1].Id,
        CategoryName: categories[1].Name,
        EquipmentTypes: equipmentTypes.filter(o => o.CategoryId === categories[1].Id).slice(0, 3),
        RegistrationNumber: "DEF6789",
        Request: true
    }
]

export function RetrieveEquipmentRequests(): Promise<IEquipmentRequest[]> {
    return new Promise(resolve => setTimeout(resolve, 1300))
        .then(() => [...equipmentRequests]);
}

export function CreateRequest(request: IEquipmentRequest): Promise<IEquipmentRequest> {

    if (equipmentRequests.length > 2)
        return new Promise((resolve, reject) => {
            throw new Error("Maximum number of requests reached");
        });

    equipmentRequests.push(request);

    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => request);
}