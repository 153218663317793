import { v4 as uuid } from "uuid";
import { IQuestionary } from '../types/IQuestionary.interface';

const documents: IQuestionary[] = [
    {
        Id: uuid(),
        Name: 'Contractor Induction',
        Description: 'This is a contractor induction form',
        Icon: 'Questionnaire',
        CreatedBy: '2021-03-20',
        CreatedOn: 'fmena@southernplanthire.com.au',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2021-03-20',

        //computed attributes
        Pending: true,
        Submitted: false,
        Completed: false,
        Approved: false,
        Failed: false,
        Expiring: false,
        Expired: false
    },
    {
        Id: uuid(),
        Name: 'Contractor Safety Induction',
        Description: 'This is a contractor safety induction form',
        Icon: 'Questionnaire',
        Score: 62.8,
        CreatedBy: '2021-03-20',
        CreatedOn: 'fmena@southernplanthire.com.au',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2021-03-20',

        //computed attributes
        Pending: false,
        Submitted: false,
        Completed: true,
        Approved: false,
        Failed: true,
        Expiring: false,
        Expired: false
    },
    {
        Id: uuid(),
        Name: 'Code of Conduct',
        Description: 'This is a code of conduct form',
        Icon: 'Questionnaire',
        CreatedBy: '2021-03-20',
        CreatedOn: 'fmena@southernplanthire.com.au',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2021-03-20',

        //computed attributes
        Pending: false,
        Submitted: true,
        Completed: false,
        Approved: false,
        Failed: false,
        Expiring: false,
        Expired: false
    },
    {
        Id: uuid(),
        Name: 'COVID-19 Safety Principles',
        Description: 'This is a COVID-19 safety principles form',
        Icon: 'Hospital',
        CreatedBy: '2021-03-20',
        CreatedOn: 'fmena@southernplanthire.com.au',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2021-03-20',

        //computed attributes
        Pending: false,
        Submitted: false,
        Completed: true,
        Approved: true,
        Failed: false,
        Expiring: false,
        Expired: false
    },
    {
        Id: uuid(),
        Name: 'Annual Feedback questionary',
        Description: 'This is a questionary form for annual feedback',
        Icon: 'Feedback',
        CreatedBy: '2021-03-20',
        CreatedOn: 'fmena@southernplanthire.com.au',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2021-03-20',

        //computed attributes
        Pending: false,
        Submitted: false,
        Completed: false,
        Approved: false,
        Failed: false,
        Expiring: false,
        Expired: true
    },
    {
        Id: uuid(),
        Name: 'Christmas party confirmation 2022',
        Description: 'This is a sample questionary form',
        Icon: 'Balloons',
        CreatedBy: '2021-03-20',
        CreatedOn: 'fmena@southernplanthire.com.au',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2021-03-20',

        //computed attributes
        Pending: false,
        Submitted: false,
        Completed: false,
        Approved: false,
        Failed: false,
        Expiring: true,
        Expired: false
    }
]

export function RetrieveQuestionnaires(): Promise<IQuestionary[]> {
    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => documents);
}