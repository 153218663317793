import { v4 as uuid } from "uuid";
import { IQuestionary } from '../types/IQuestionary.interface';

const documents: IQuestionary[] = [
    {
        Id: uuid(),
        Name: 'Company Feedback 2021',
        Description: 'This is a company feedback form',
        Icon: 'Questionnaire',
        CreatedBy: '2021-12-31',
        CreatedOn: 'fmena@southernplanthire.com.au',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2022-03-21',

        //computed attributes
        Pending: false,
        Submitted: false,
        Completed: true,
        Approved: true,
        Failed: false,
        Expiring: false,
        Expired: false
    },
    {
        Id: uuid(),
        Name: 'Company Feedback 2022',
        Description: 'This is a company feedback form',
        Icon: 'Questionnaire',
        CreatedBy: '2022-10-10',
        CreatedOn: 'fmena@southernplanthire.com.au',
        LastModifiedBy: 'John Smith',
        LastModifiedOn: '2022-03-21',

        //computed attributes
        Pending: true,
        Submitted: false,
        Completed: false,
        Approved: false,
        Failed: false,
        Expiring: false,
        Expired: false
    },
]

export function RetrieveQuestionnaires(): Promise<IQuestionary[]> {
    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => documents);
}