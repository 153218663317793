import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd"
import { IOperatorRequest } from "../../../types/IOperatorRequest.interface";
import { v4 as uuid } from 'uuid';
import { useState } from "react";
import OperatorRequestCreateModal from "../OperatorRequestCreateModal/OperatorRequestCreateModal.component";

interface IPropsEvents {
    create: (request: IOperatorRequest) => void;
}

interface IProps extends IPropsEvents {
    searching: boolean;
    creating: boolean;
}

export default (props: IProps) => {

    const [visibility, setVisibility] = useState(false);
    
    const open = () => setVisibility(true);
    const close = () => setVisibility(false);

    const create = (request: IOperatorRequest) => {
        close();
        props.create(request);
    }

    return <>
        <Button type="primary" loading={props.creating} disabled={props.creating || props.searching || visibility} onClick={open} icon={<PlusOutlined />} >
            {props.creating ? "Creating" : "Create Operator"}
        </Button>
        <OperatorRequestCreateModal creating={props.creating} visibility={visibility} close={close} create={create} />
    </>
}