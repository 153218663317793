import { Table } from "antd";
import QuestionaryStatusIcon from "../QuestionaryStatusIcon/QuestionaryStatusIcon.component";
import { IQuestionary } from '../../../types/IQuestionary.interface';
import { ColumnsType } from "antd/lib/table";
import { Icon } from "@fluentui/react";
import { Summarize } from "../../../common/utilities/string.common";
import QuestionaryActionButton from "../QuestionaryActionButton/QuestionaryActionButton.component";
import { properties } from "../../../common/properties/Spin.properties";

interface IPropsEvents {
    refresh: (questionary: IQuestionary) => void;
}

interface IProps extends IPropsEvents {
    searching: boolean;
    questionnaires: IQuestionary[];
}

export default (props: IProps) => {


    const columns: ColumnsType<IQuestionary> = [
        {
            align: 'left',
            title: <Icon iconName="Page" />,
            render: record => <Icon iconName={record.Icon} />
        },
        {
            title: 'Name',
            render: record =>
                <>
                    <div>
                        {record.Name}
                    </div>
                    <small>
                        {
                            Summarize(record?.Description)
                        }
                    </small>
                </>
        },
        {
            title: 'Modified On',
            dataIndex: 'LastModifiedOn'
        },
        {
            title: 'Modified By',
            dataIndex: 'LastModifiedBy'
        },
        {
            title: 'Score',
            dataIndex: 'Score'
        },
        {
            title: 'Status',
            render: record => <QuestionaryStatusIcon questionary={record} />
        },
        {
            align: 'right',
            render: record => <QuestionaryActionButton refresh={props.refresh} questionary={record} />
        }
    ];

    return <Table
        size="small"
        loading={{ ...properties, spinning: props.searching }}
        rowKey={o => o.Id}
        columns={columns}
        dataSource={props.questionnaires}
    />
}