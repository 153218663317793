import { Button } from "antd"
import { IEquipmentRequest } from "../../../types/IEquipmentRequest.interface";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from 'react';
import EquipmentRequestCreateModal from "../EquipmentRequestCreateModal/EquipmentRequestCreateModal.component";

interface IProsEvents {
    create: (request: IEquipmentRequest) => void;
}

interface IProps extends IProsEvents {
    searching: boolean;
    creating: boolean;
}

export default (props: IProps) => {

    const [visibility, setVisibility] = useState(false);

    const open = () => setVisibility(true);

    const close = () =>
        setVisibility(false);

    const create = (request: IEquipmentRequest) => {
        close();
        props.create(request);
    }

    return <>
        <Button type="primary" loading={props.creating} disabled={props.creating || props.searching || visibility} onClick={open} icon={<PlusOutlined />} >
            {props.creating ? "Creating" : "Create Equipment"}
        </Button>
        <EquipmentRequestCreateModal creating={props.creating} visibility={visibility} close={close} create={create} />
    </>
}