import { Divider } from "antd";
import OperatorQuestionaryFilter from "../../components/OperatorQuestionary/OperatorQuestionaryFilter/OperatorQuestionaryFilter.component";
import QuestionaryTable from "../../components/Questionary/QuestionaryTable/QuestionaryTable.component";
import OperatorQuestionaryContext from "../../contexts/OperatorQuestionary.context";
import OperatorQuestionaryProvider from "../../providers/OperatorQuestionary.provider";
import { Content } from 'antd/lib/layout/layout';

export default () => {
    return <OperatorQuestionaryProvider>
        <OperatorQuestionaryContext.Consumer>
            {
                ({ searching, questionnaires, refresh }) => {
                    return <Content>
                        <OperatorQuestionaryFilter searching={searching} refresh={refresh} />
                        <Divider />
                        <QuestionaryTable searching={searching} questionnaires={questionnaires} refresh={refresh} />
                    </Content>
                }
            }
        </OperatorQuestionaryContext.Consumer>
    </OperatorQuestionaryProvider>
}