import { Table } from "antd"
import { ColumnsType } from "antd/lib/table";
import { IDocument } from "../../../types/IDocument.interface";
import DocumentExtensionIcon from "../DocumentExtensionIcon/DocumentExtensionIcon.component";
import DocumentStatusIcon from "../DocumentStatusIcon/DocumentStatusIcon.component";
import DocumentActionButton from "../DocumentActionButton/DocumentActionButton.component";
import { properties } from "../../../common/properties/Spin.properties";

interface IPropsEvents {
    refresh: (document: IDocument) => void;
}

interface IProps extends IPropsEvents {
    searching: boolean;
    documents: IDocument[];
}

export default (props: IProps) => {

    const columns: ColumnsType<IDocument> = [
        {
            align: 'left',
            render: record => <DocumentExtensionIcon extension={record} />,
        },
        {
            render: record => <>
                <div>
                    {record.TypeName}
                </div>
                <small>
                    {record.FileName}
                </small>
            </>
        },
        {
            title: 'Modified On',
            dataIndex: 'LastModifiedOn'
        },
        {
            title: 'Modified By',
            dataIndex: 'LastModifiedBy'
        },
        {
            title: 'File Size',
            render: record => `${record.FileSize} KB`
        }, {
            title: 'Status',
            render: record => <DocumentStatusIcon document={record} />
        },
        {
            align: 'right',
            render: record => <DocumentActionButton document={record} refresh={props.refresh} />
        }
    ];

    return <Table
        size="small"
        loading={{ ...properties, spinning: props.searching }}
        rowKey={o => o.Id}
        columns={columns}
        dataSource={props.documents}
    />
}

