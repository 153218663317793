
import { useEffect, useState } from "react";
import { RetrieveOperatorRequests, CreateRequest, operatorRequests } from '../services/OperatorRequest.service';
import OperatorRequestContext from "../contexts/OperatorRequest.context";
import { IOperatorRequest } from '../types/IOperatorRequest.interface';
import { v4 as uuid } from 'uuid';
import { message } from "antd";

export default ({ children }: any) => {

    const [requests, setRequests] = useState([] as IOperatorRequest[]);
    const [searching, setSearching] = useState(false as boolean);
    const [creating, setCreating] = useState(false as boolean);

    const refresh = () => {
        setSearching(true);
        RetrieveOperatorRequests()
            .then((response: IOperatorRequest[]) => setRequests(response))
            .finally(() => setSearching(false))
    }

    const create = (request: IOperatorRequest) => {

        const notification = message.loading('Operator request in progress..')
            .then(_ => setCreating(false))

        setCreating(true);

        //TODO: delete this mock
        const mock: IOperatorRequest = {
            Id: uuid(),
            Name: `Name ${operatorRequests.length.toString().padStart(2, '0')}`,
            Email: `operator${operatorRequests.length.toString().padStart(2, '0')}@company.com.au`,
            PhoneNumber: "0426280597",
            Request: true
        }

        CreateRequest(mock)
            .finally(() => notification)
            .then(_ => { message.success('Operator request completed'); refresh(); })
            .catch(exception => message.error('Operator request failed'))
    }

    useEffect(() => { refresh() }, []);

    return <OperatorRequestContext.Provider value={{ searching, requests, refresh, creating, create }}>
        {children}
    </OperatorRequestContext.Provider>
};