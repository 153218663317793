import { v4 as uuid } from "uuid";
import { IOperator } from "../types/IOperator.interface";

const collection: IOperator[] = [
    {
        Id: uuid(),
        Name: "Jimmy Doe",
        Email: "jimmy@company.com.au",
        PhoneNumber: "0426280597"
    },
    {
        Id: uuid(),
        Name: "Mark Doe",
        Email: "mark@company.com.au",
        PhoneNumber: "0426280597"
    },
    {
        Id: uuid(),
        Name: "Josh Doe",
        Email: "josh@company.com.au",
        PhoneNumber: "0426280597"
    },
    {
        Id: uuid(),
        Name: "Alex Doe",
        Email: "alex@company.com.au",
        PhoneNumber: "0426280597"
    },
    {
        Id: uuid(),
        Name: "James Doe",
        Email: "james@company.com.au",
        PhoneNumber: "0426280597"
    },
]

export function RetrieveOperators(): Promise<IOperator[]> {
    return new Promise(resolve => setTimeout(resolve, 1000))
        .then(() => collection);
}