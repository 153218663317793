import { useEffect, useState } from "react";
import { RetrieveEquipmentRequests, CreateRequest, equipmentRequests } from '../services/EquipmentRequest.service';
import EquipmentRequestContext from "../contexts/EquipmentRequest.context";
import { IEquipmentRequest } from "../types/IEquipmentRequest.interface";
import { message } from "antd";
import { v4 as uuid } from 'uuid';
import { categories } from "../services/Category.service";
import { equipmentTypes } from "../services/EquipmentType.service";

export default ({ children }: any) => {

    const [requests, setRequests] = useState([] as IEquipmentRequest[]);
    const [searching, setSearching] = useState(false as boolean);
    const [creating, setCreating] = useState(false as boolean);

    const refresh = () => {
        setSearching(true);
        RetrieveEquipmentRequests()
            .then((response: IEquipmentRequest[]) => setRequests(response))
            .finally(() => setSearching(false))
    }

    const create = (request: IEquipmentRequest) => {

        const notification = message.loading('Equipment request in progress..')
            .then(_ => setCreating(false))

        setCreating(true);

        //TODO: delete this mock
        const mock: IEquipmentRequest = {
            Id: uuid(),
            CategoryId: categories[1].Id,
            CategoryName: categories[1].Name,
            EquipmentTypes: equipmentTypes.filter(o => o.CategoryId === categories[1].Id).slice(0, 2),
            RegistrationNumber: `REG-${equipmentRequests.length.toString().padStart(5, '0')}`,
            Request: true
        };


        CreateRequest(mock)
            .finally(() => notification)
            .then(_ => { message.success('Operator request completed'); refresh() })
            .catch(exception => message.error('Equipment request failed'))
    }

    useEffect(() => { refresh() }, []);

    return <EquipmentRequestContext.Provider value={{ searching, requests, refresh, creating, create }}>
        {children}
    </EquipmentRequestContext.Provider>
};