import { useEffect, useState } from "react";
import { RetrieveQuestionnaires } from '../services/OperatorQuestionary.service';
import OperatorQuestionaryContext from "../contexts/OperatorQuestionary.context";
import { IQuestionary } from '../types/IQuestionary.interface';

export default ({ children }: any) => {

    const [questionnaires, setQuestionnaires] = useState(undefined as IQuestionary[] | undefined);
    const [searching, setSearching] = useState(false as boolean);

    const refresh = () => {
        setSearching(true);
        RetrieveQuestionnaires().then((questionnaires: IQuestionary[]) => setQuestionnaires(questionnaires))
            .finally(() => setSearching(false))
    }

    useEffect(() => { refresh() }, []);

    return <OperatorQuestionaryContext.Provider value={{ searching, questionnaires, refresh }}>
        {children}
    </OperatorQuestionaryContext.Provider>
};