import { useEffect, useState } from "react";
import { RetrieveQuestionnaires } from '../services/SubcontractorQuestionary.service';
import { IQuestionary } from '../types/IQuestionary.interface';
import SubcontractorQuestionaryContext from "../contexts/SubcontractorQuestionary.context";

export default ({ children }: any) => {

    const [questionnaires, setQuestionnaires] = useState(undefined as IQuestionary[] | undefined);
    const [searching, setSearching] = useState(false as boolean);

    const refresh = () => {
        setSearching(true);
        RetrieveQuestionnaires().then((questionnaires: IQuestionary[]) => setQuestionnaires(questionnaires))
            .finally(() => setSearching(false))
    }

    useEffect(() => { refresh() }, []);

    return <SubcontractorQuestionaryContext.Provider value={{ searching, questionnaires, refresh }}>
        {children}
    </SubcontractorQuestionaryContext.Provider>
};