import { Table } from "antd"
import { ColumnsType } from "antd/lib/table";
import { IRegion } from "../../../types/IRegion.interface";
import { useMemo } from "react";
import { properties } from "../../../common/properties/Spin.properties";

interface IPropsEvents {
    select: (selection: string[]) => void;
}

interface IProps extends IPropsEvents {
    regions: IRegion[];
    searching: boolean;
    selection: string[]
}

export default (props: IProps) => {

    const componentSelection = useMemo(() => ({

        selectedRowKeys: props.selection,
        
        onChange: (selectedRowKeys: React.Key[], selectedRows: IRegion[]) =>
            props.select(selectedRowKeys as string[])
            
    }), [props.regions, props.selection])

    return <Table
        size="small"
        loading={{ ...properties, spinning: props.searching }}
        rowSelection={componentSelection}
        rowKey={o => o.Id}
        columns={columns}
        dataSource={props.regions}
    />
}

const columns: ColumnsType<IRegion> = [
    {
        title: 'Name',
        dataIndex: 'Name',
    },
    {
        title: 'Description',
        dataIndex: 'Description',
    }
];