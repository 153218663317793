import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styles from './LoadingComponent.module.scss';

interface IProps {
    message: string;
    fullScreen?: boolean
}

export default ({ message = 'Loading content, please wait ...', fullScreen = false }: IProps) =>
    <Spin size='large' indicator={<LoadingOutlined spin />}
        className={fullScreen ? styles.container : styles.none} tip={message}>
    </Spin>
