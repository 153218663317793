import { Divider } from 'antd';
import SubcontractorQuestionaryFilter from "../../components/SubcontractorQuestionary/SubcontractorQuestionaryFilter/SubcontractorQuestionaryFilter.component";
import QuestionaryTable from "../../components/Questionary/QuestionaryTable/QuestionaryTable.component";
import SubcontractorQuestionaryContext from "../../contexts/SubcontractorQuestionary.context";
import SubcontractorQuestionaryProvider from "../../providers/SubcontractorQuestionary.provider";
import { Content } from 'antd/lib/layout/layout';

export default () => {
    return <SubcontractorQuestionaryProvider>
        <SubcontractorQuestionaryContext.Consumer>
            {
                ({ searching, questionnaires, refresh }) => {
                    return <Content>
                        <SubcontractorQuestionaryFilter searching={searching} refresh={refresh} />
                        <Divider />
                        <QuestionaryTable searching={searching} questionnaires={questionnaires} refresh={refresh} />
                    </Content>
                }
            }
        </SubcontractorQuestionaryContext.Consumer>
    </SubcontractorQuestionaryProvider>
}