import { Col, Row } from 'antd';
import { IUserAgreement } from '../../../types/IUserAgreement.interface';

interface IProps {
    agreement: IUserAgreement
}
export default ({ agreement }: IProps) => {
    return <div key={agreement.Id}>
        <Row>
            <Col offset={1} span={24}>
                <div>
                    <h3>
                        {agreement.Name}
                    </h3>
                </div>
            </Col>
        </Row>
        <Row justify="center" >
            <Col offset={1} span={22}>
                <p>
                    {agreement.Content}
                </p>
            </Col>
        </Row>
    </div>
}