import { useAuth0, User } from '@auth0/auth0-react';
import { CommandBar, ContextualMenuItemType } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

export default () => {

  const navigate = useNavigate();

  const { user, logout } = useAuth0();

  const items = [
    {
      key: 'company',
      name: 'Company',
      iconProps: {
        iconName: true ? "EMI" : "AlertSolid",
        style: { color: true ? "Exchange" : "red" }
      },
      subMenuProps: {
        items: [
          {
            key: 'companyDocuments',
            text: 'Documents',
            iconProps: { iconName: 'TextDocument' },
            onClick: () => navigate('/company/document')
          },
          {
            key: 'companyQuestionary',
            text: 'Forms',
            iconProps: {
              iconName: true ? "Questionnaire" : "AlertSolid",
              style: { color: true ? "Exchange" : "red" }
            },
            onClick: () => navigate('/company/questionary')
          }
        ],
      },
    },
    {
      key: "myDocuments",
      name: "My Documents",
      iconProps: { iconName: "TextDocument" },
      onClick: () => navigate('/operator/document')
    },
    {
      key: "myQuestionnaires",
      name: "My Forms",
      iconProps: { iconName: "Questionnaire" },
      onClick: () => navigate('/operator/questionary')
    },
    // {
    //   key: "questionary",
    //   commandBarButtonAs: () => <Button style={{ alignSelf: "center" }} type="text" loading onClick={_ => navigate('/operator/document')}>
    //     My Forms
    //   </Button>
    // },
    {
      key: "equipment",
      name: "Equipment",
      iconProps: {
        iconName: true ? "Manufacturing" : "AlertSolid",
        style: { color: true ? "Exchange" : "red" }
      },
      onClick: () => navigate('/company/equipment')
    },
    {
      key: "operator",
      name: "Operator",
      iconProps: {
        iconName: true ? "Contact" : "AlertSolid",
        style: { color: true ? "Exchange" : "red" }
      },
      onClick: () => navigate('/company/operator')
    },
    {
      key: "region",
      name: "Working Area",
      iconProps: { iconName: "MapPin" },
      onClick: () => navigate('/company/region')
    },
  ];

  const farItems = (user?: User, logout?: any) => [
    // {
    //   key: "search",
    //   onRender: () => <SearchBox placeholder="Search" className={styles.search} />
    // },
    {
      key: "settings",
      iconProps: { iconName: "Settings" },
      subMenuProps: {
        items: [
          {
            disabled: true,
            key: 'username',
            text: user && user.nickname
          },
          {
            key: 'profile',
            text: 'Profile',
            disabled: true,
            iconProps: { iconName: 'PlayerSettings' },
            onClick: () => navigate('/profile'),
          },
          {
            key: 'settings_divider',
            itemType: ContextualMenuItemType.Divider
          },
          {
            key: 'support',
            text: 'Support',
            iconProps: { iconName: 'Telemarketer' },
            onClick: () => navigate('/support'),
            disabled: true
          },
          {
            key: 'logout',
            text: 'Logout',
            disabled: !logout,
            iconProps: { iconName: 'SignOut' },
            onClick: logout
          },
        ],
      },
    },
  ];

  if (user)
    return <CommandBar
      items={items}
      overflowItems={overflowItems}
      farItems={farItems(user, logout)}
    />

  return <>
  </>
}

const overflowItems = [
  {
    disabled: true,
    key: "docket",
    name: "Dockets",
    iconProps: { iconName: "DocumentSet" },
  },
  {
    disabled: true,
    key: "availability",
    name: "Availability",
    iconProps: { iconName: "Calendar" },
  },
];