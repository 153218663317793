import { ArrowRightOutlined, FileProtectOutlined } from '@ant-design/icons';
import { Alert, AvatarProps, Button, PageHeader, Space } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import UserAgreementContext from '../../contexts/UserAgreement.context';
import styles from './style.module.scss';
import { IUserAgreement } from '../../types/IUserAgreement.interface';
import UserAgreementContent from '../../components/UserAgreement/UserAgreementContent/UserAgreementContent.component';

export default () => {

    const avatarProperties: AvatarProps = {
        icon: <FileProtectOutlined className={styles.avatarIcon} />,
        className: styles.avatar,
        size: 'large'
    }

    return <UserAgreementContext.Consumer>
        {
            ({ searching, agreements, accept, accepting }) => {
                if (Array.isArray(agreements))
                    return <Content>
                        <PageHeader avatar={avatarProperties} title="User Agreement" extra=
                            {
                                <Button disabled={searching} loading={accepting} key="headerAcceptComponent"
                                    type='primary' icon={<ArrowRightOutlined />} onClick={accept}>
                                    Accept
                                </Button>
                            } >
                        </PageHeader>

                        <Content>
                            {
                                agreements.map((agreement: IUserAgreement) =>
                                    <UserAgreementContent key={agreement.Id} agreement={agreement} />)
                            }
                        </Content>

                        <Footer className={styles.footer}>
                            <Space size="small">
                                <Button disabled={searching} loading={accepting} key="footerAcceptComponent"
                                    type='primary' icon={<ArrowRightOutlined />} onClick={accept}>
                                    Accept
                                </Button>
                            </Space>
                        </Footer>
                    </Content>
            }
        }
    </UserAgreementContext.Consumer>
}
